import React, { useEffect, useState } from 'react';
import './UsersChatList.css';
import { ChatTexts } from '../assets/icons/ChatTexts';
import ChatItem from '../helpers/chatItem';
import FloatOptionsBox from '../helpers/floatOptionsBox';
import { confirmAlert } from 'react-confirm-alert';
import { ThreeOptionsVertical } from '../assets/icons/ThreeOptionsVertical';
import { Position_options_box, Save_local_list } from '../utils/constants';
import { Pin } from '../assets/icons/Pin';
import { Trash } from '../assets/icons/Trash';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eliminarChat } from '../services/ChatService';
import { toast } from 'react-toastify';

const UsersChatList = ({
  chats,
  currentThemeParams,
  handleChatId,
  selectedChatId,
  handleSidebarDimensions,
  reduceSidebar,
  handleOptionsClick,
  selectedOptions,
  resetStates,
  skeletonNewChat,
  showScroll
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [chatsContainer, setChatsContainer] = useState([]);
  const [selecChat, setSelectChat] = useState([]);

  const [newDate, setNewDate] = useState(null);
  const [boxItemSelected, setBoxItemSelected] = useState(null);

  /**
   * Componentes del cuadro de opciones
   */
  const { OptionsBoxContainer, OptionsItems } = FloatOptionsBox();

  const [loader, setLoader] = useState(false);
  /**
   * Valida el tema actual para definir clase que tendrá la linea separadora entre fijados y lista de chats
   */
  const changeLineStyle = currentThemeParams === 'dark' ? 'chats-fixed-lists' : 'chats-fixed-dark-lists';

  /**
   * Estados para almacenar posiciones de pantalla para la clase sidebar-chat-item-three-options-container-selected
   */
  const [position, setPosition] = useState({
    topPos: -100,
    leftPos: -100
  });

  /**
   * Consulta primero la posicion de la nueva clase recien asignada sidebar-chat-item-three-options-container-selected y retarda 0.35seg el seteo de valore
   * para permitir que no lleguen undefined y poder renderizar el componente de opciones justo al lado de dicha posicion.
   */
  const timerCheck = () => {
    const checkPosition = () => {
      const element = document.querySelector('.sidebar-chat-item-three-options-container-selected');
      const rect = element?.getBoundingClientRect();
      const posicionX = rect?.left; // Posición horizontal en el eje X
      const posicionY = rect?.top; // Posición vertical en el eje Y
      setPosition({
        topPos: posicionY,
        leftPos: posicionX
      });
    };
    setPosition({
      topPos: -100,
      leftPos: -100
    });
    setTimeout(() => {
      checkPosition();
    }, 350);
  };

  /**
   * Escucha cuando se monta el componente o se alteran los parametros para setear newDate con al fecha actual y tambien
   * boxItemSelected con el primer elemento del arreglo de chatsContainer.
   */
  useEffect(() => {
    let transformArr = Object.entries(chatsContainer)[0];
    transformArr?.map((e) => {
      setBoxItemSelected(e);
    });
    let recentDate = new Date().toISOString().split('T')[0];
    if (!newDate) {
      setNewDate(recentDate);
    }
  }, [newDate, chatsContainer]);

  /**
   * Recibe fecha y chats para calcular la ultima fecha del arreglo y no pintarla
   * @param {*} date
   * @param {*} chat
   * @returns
   */
  const checkLastDate = (date, chatItems) => {
    let printDate = '';
    if (chatItems?.length > 0 && date !== newDate) {
      printDate = date;
    }
    return printDate;
  };

  /**
   * Consulta si el primer elemento del arreglo incluye la fecha actual y si el tamaño del container es mayor a 0 para intercambiar
   * titulo de la lista de chats, si el primer elemento del arreglo coincide con la fecha pinta 'Recientes', sino coincide pinta '' permitiendo
   * mostrar la fecha del contenedir de chats vacio, y si existe algun elemento fijado pinta 'Fijados'.
   * @returns
   */
  function showRecent() {
    let printDate = '';
    let transformArr = Object.entries(chatsContainer)[0];
    if (!!transformArr && transformArr?.includes(newDate) && boxItemSelected?.length > 0) {
      printDate = 'Recientes';
    } else if (selecChat.length > 0) {
      printDate = 'Fijados';
    }
    return printDate;
  }

  /**
   * Sirve para guardar local la lista fijada previamente
   */
  function saveArray(item) {
    const jsonString = JSON.stringify(item);
    localStorage.setItem(Save_local_list.FIXED_LOCAL_LIST_NAME, jsonString);
  }

  /**
   * Sirve para consultar la lista previa de fijados que estan guardadas local
   */
  function getArray() {
    const jsonString = localStorage.getItem(Save_local_list.FIXED_LOCAL_LIST_NAME);
    const fixed_lists_array = JSON.parse(jsonString);
    return fixed_lists_array;
  }

  /**
   * Escucha los cambios de selecChat para luego guardarlos localmente
   */
  useEffect(() => {
    if (!!getArray() && getArray()?.length !== 0 && selecChat?.length === 0 && !isMounted) {
      setSelectChat(getArray());
      setIsMounted(true);
    }
    if (selecChat?.length === 0 && isMounted) {
      saveArray([]);
    }
    if (selecChat?.length > 0) {
      saveArray(selecChat);
    }
  }, [selecChat]);

  /**
   * Recibe chat-id y valida contra selecChat fijados si se encuentran seleccionados para luego ocultarlos de la lista original.
   * @param {*} id - string recibido con el id del chat
   * @returns
   */
  const styleHidden = (id) => {
    let display = 'flex';
    const selectCandidate = selecChat?.find((chat) => chat.id === id);
    if (selectCandidate) {
      display = 'none';
    }
    return display;
  };

  /**
   * Recibe el objeto copleto del chat para almacenarlo en un array de elementos fijados, el cual se almacena posteriormente en localstorage.
   * @param {*} item - Objeto completo con la información del chat para validar inicialmente y luego almacenarlo.
   */
  const pushChat = (item) => {
    const selectCandidate = selecChat?.find((chat) => chat.id === item.id);
    if (selectCandidate) {
      const indice = selecChat.indexOf(selectCandidate);
      selecChat.splice(indice, 1);
      if (selecChat?.length === 0) {
        saveArray([]);
        setSelectChat([]);
      } else {
        setSelectChat([...selecChat]);
      }
    } else {
      setSelectChat([...selecChat, item]);
    }
  };

  /**
   * Consulta chatsContainer, para saber si esta vacio y asignar valor, o Chats para revisar si su tamaño es diferente a chatsContainer y reestablecerlo.
   */
  useEffect(() => {
    setChatsContainer(chats);
  }, [chats]);

  if (!chats) {
    return null;
  }

  /**
   * Recibe chat.id para remover conversacion
   * @param {*} id - string recibido con el id del chat
   */
  const removeChat = async (id) => {
    const response = await eliminarChat(id);
    if (!!response && !!response.data && response.data.respuesta == true) {
      toast.success('Se ha eliminado con exito', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
      Object.entries(chatsContainer).map((chatItems) => {
        let arr = chatItems[1];
        // Busca el id en la lista de chats, si lo consigue lo remueve de la lista
        const removeCandidate = arr?.find((chat) => chat.id === id);
        if (removeCandidate) {
          const indice = arr.indexOf(removeCandidate);
          arr.splice(indice, 1);
        }
        // Busca el id en la lista de chats fijados, si lo consigue lo remueve de la lista
        const removeFixedCandidate = selecChat?.find((chat) => chat.id === id);
        if (removeFixedCandidate) {
          const indice = selecChat.indexOf(removeFixedCandidate);
          selecChat.splice(indice, 1);
          if (selecChat?.length === 0) {
            saveArray([]);
            setSelectChat([]);
          } else {
            setSelectChat([...selecChat]);
          }
        }
        // Reinicia estados para icono de cargando, estilos de boton tres puntos de opciones y tambien gatilla nuevo chat al eliminar el anterior
        setLoader(false);
        showScroll();
        if (id === selectedChatId) {
          handleOptionsClick(null);
          resetStates();
        }
      });
    } else {
      toast.error('Error al intentar eliminar chat', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
      // Reinicia estados para icono de cargando, estilos de boton tres puntos de opciones
      setLoader(false);
      handleOptionsClick(id);
    }
  };

  /**
   * Función para eliminar chat
   */
  const removeMessages = (id) => {
    confirmAlert({
      title: 'Confirmar',
      message: '¿Deseas eliminar este mensaje?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => [
            setLoader(true),
            setTimeout(() => {
              removeChat(id);
            }, 300)
          ]
        },
        {
          label: 'No',
          onClick: () => {
            handleOptionsClick(id);
          }
        }
      ]
    });
  };

  /**
   * Recive el chat.id para cambiar de opciones de tema en botones de tres puntos de opciones para los chats de la lista
   * asi poder intercambiar backgrounds cuando estan seleccionados tanto el chat como las opciones con el mismo id o no
   * @param {*} id - string
   * @returns
   */
  const conformationStyle = (id) => {
    let styles = '';
    if (selectedChatId === selectedOptions) {
      styles =
        id === selectedOptions
          ? 'sidebar-chat-item-three-options-container-selected themeSidebar-three-options'
          : 'sidebar-chat-item-three-options-container';
    } else {
      styles =
        id === selectedOptions
          ? 'sidebar-chat-item-three-options-container-selected themeSidebar-three-options-noequals'
          : 'sidebar-chat-item-three-options-container';
    }
    return styles;
  };

  /**
   * Recibe lista de chats para evaluar si el contenedor tiene un tamaño mayor a 0 y si los elementos fijados coinciden con la
   * cantidad de elementos en el contenedor de chat para ocultarlo o pintarlo.
   * @param {*} chatItems - contenedor de chats por fecha.
   * @returns
   */
  function showChatsContainer(chatItems) {
    let arr = [];
    let displayContent = 'none';
    if (chatItems?.length > 0) {
      chatItems?.map((item) => {
        const showDate = selecChat?.find((chat) => chat.id === item.id);
        if (showDate) {
          item.id === showDate.id ? arr.push(item) : null;
        }
      });
    }
    arr?.length === chatItems?.length ? (displayContent = 'none') : (displayContent = 'flex');
    return displayContent;
  }

  return (
    <div className="chat-list">
      <div>
        <div className="sidebar-list-title-container">
          <h2 className="sidebar-list-title poppins-regular">{showRecent()}</h2>
        </div>

        <div className={`sidebar-list-container ${selecChat?.length !== 0 && changeLineStyle}`}>
          <div className={`${selecChat?.length !== 0 ? 'sidebar-chat-item' : 'sidebar-chat-item-fixed-clean'}`}>
            <ul className="sidebar-list-container-ul">
              {selecChat?.map((chat) => (
                <div key={chat.id}>
                  <li
                    className={
                      chat.id === selectedChatId
                        ? 'sidebar-chat-item-li-selected themeSidebar-new-chat'
                        : 'sidebar-chat-item-li'
                    }>
                    <div style={{ width: '80%' }}>
                      <ChatItem
                        reduceSidebar={reduceSidebar}
                        handleSidebarDimensions={handleSidebarDimensions}
                        chatName={chat.chat_name.trim()}
                        chatId={chat.id}
                        onChatClick={handleChatId}
                        icon={<ChatTexts theme={currentThemeParams} />}
                      />
                    </div>
                    <button
                      className={conformationStyle(chat.id)}
                      onClick={() => [handleOptionsClick(chat.id), timerCheck()]}>
                      {loader && selectedOptions === chat.id ? (
                        <span className="loader-icon themeLoaderIcon"></span>
                      ) : (
                        <Pin theme={currentThemeParams} styles={'sidebar-chat-item-three-options'} />
                      )}
                    </button>
                    <div className="sidebar-chat-item-three-options-container-mobile">
                      <Pin theme={currentThemeParams} styles={'sidebar-chat-item-three-options'} />
                    </div>
                    <OptionsBoxContainer
                      X={position.leftPos + Position_options_box.LEFT}
                      Y={position.topPos + Position_options_box.TOP}
                      styles="float-options"
                      optionItem={[
                        <OptionsItems
                          key="Fijar"
                          optionName="Desfijar"
                          icon={<FontAwesomeIcon icon={faCancel} alt="Reportes" className="cursor-pointer" />}
                          action={() => [pushChat(chat), handleOptionsClick(chat.id)]}
                        />,
                        <OptionsItems
                          key="Eliminar"
                          optionName="Eliminar"
                          icon={<Trash theme={currentThemeParams} />}
                          action={() => removeMessages(chat.id)}
                        />
                      ]}
                      compareState={selectedOptions}
                      validator={chat.id}
                      load={loader}
                    />
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>

        {skeletonNewChat && <span className="skeleton-icon themeSkeletonIcon"></span>}
        <div className="sidebar-list-container">
          {Object.entries(chatsContainer).map(([date, chatItems]) => (
            <div key={date} className="sidebar-chat-item" style={{ display: showChatsContainer(chatItems) }}>
              <h2 className="sidebar-list-date poppins-regular">{checkLastDate(date, chatItems)}</h2>
              <ul className="sidebar-list-container-ul">
                {chatItems.map((chat) => (
                  <div key={chat.id}>
                    <li
                      style={{ display: styleHidden(chat.id) }}
                      className={
                        chat.id === selectedChatId
                          ? 'sidebar-chat-item-li-selected themeSidebar-new-chat'
                          : 'sidebar-chat-item-li'
                      }>
                      <div style={{ width: '80%' }}>
                        <ChatItem
                          reduceSidebar={reduceSidebar}
                          handleSidebarDimensions={handleSidebarDimensions}
                          chatName={chat.chat_name.trim()}
                          chatId={chat.id}
                          onChatClick={handleChatId}
                          icon={<ChatTexts theme={currentThemeParams} />}
                        />
                      </div>
                      <button
                        className={conformationStyle(chat.id)}
                        onClick={() => [handleOptionsClick(chat.id), timerCheck()]}>
                        {loader && selectedOptions === chat.id ? (
                          <span
                            className="loader-icon themeLoaderIcon"
                            style={{ display: selectedOptions === chat.id ? 'flex' : 'none' }}></span>
                        ) : (
                          <ThreeOptionsVertical theme={currentThemeParams} styles={'sidebar-chat-item-three-options'} />
                        )}
                      </button>
                      <OptionsBoxContainer
                        X={position.leftPos + Position_options_box.LEFT}
                        Y={position.topPos + Position_options_box.TOP}
                        styles="float-options"
                        optionItem={[
                          <OptionsItems
                            key="Fijar"
                            optionName="Fijar"
                            icon={<Pin theme={currentThemeParams} />}
                            action={() => [pushChat(chat), handleOptionsClick(chat.id)]}
                          />,
                          <OptionsItems
                            key="Eliminar"
                            optionName="Eliminar"
                            icon={<Trash theme={currentThemeParams} />}
                            action={() => removeMessages(chat.id)}
                          />
                        ]}
                        compareState={selectedOptions}
                        validator={chat.id}
                        load={loader}
                      />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsersChatList;
