import React, { useState, useEffect } from 'react';
import ReportClientsService from '../../services/ReportClientsService';
import { Orbit } from '@uiball/loaders';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ReportFilterComponent.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Modal from './ModalComponent';
import logotinet from '../../assets/images/logo-tinet-new.png';
import { ORIGIN } from '../../utils/constants';

const ReportFilterComponent = ({ onFilterChange, isExportable }) => {
  const [respuestaClientes, setRespuestaClientes] = useState(null);
  const [clientValue, setClientValue] = useState('Todos');
  const [loadingClients, setLoadingClients] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [originValue, setOriginValue] = useState(ORIGIN.ALL);

  const openModal = (title, message) => {
    setModalContent({ title, message });
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  useEffect(() => {
    let isMounted = true;
    async function handleLoadClients() {
      setLoadingClients(true);
      try {
        const respuesta = await ReportClientsService();

        if (isMounted && respuesta && respuesta.data.respuesta) {
          setRespuestaClientes(respuesta.data.respuesta);
        } else {
          console.error('La respuesta no contiene los datos esperados:', respuesta);
        }
      } catch (error) {
        console.error('Error al cargar los clientes:', error);
      }

      if (isMounted) {
        setLoadingClients(false);
      }
    }
    handleLoadClients();
    return () => {
      isMounted = false;
    };
  }, []);
  const handleExport = () => {
    const input = document.querySelector('.export-pdf');

    if (input) {
      setTimeout(() => {
        html2canvas(input, { scale: 1, useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 210; // Ancho de una hoja A4 en mm
          const pageHeight = 295; // Altura de una hoja A4 en mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;

          const pdf = new jsPDF('p', 'mm');
          let position = 0;

          // Establecer el color del fondo (por ejemplo, un gris claro)
          pdf.setFillColor(230, 230, 230); // RGB para gris claro
          pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');
          // Agregar logo en la esquina superior derecha
          pdf.addImage(logotinet, 'PNG', 150, 10, 50, 15); // Los últimos dos números son el ancho y alto del logo

          // Define un título para el PDF y la posición vertical del título
          const title = `Reporte Athena (${clientValue})`;
          const titleMargin = 30; // Margen superior para el título, incrementado para hacer espacio para el logo

          // Agrega el título en la primera página
          pdf.setFontSize(18);
          pdf.text(title, 105, titleMargin, null, null, 'center'); // Centra el título

          // Ajusta la posición del canvas para dejar espacio para el título y el logo
          position = titleMargin + 10; // Agrega un espacio adicional de 10 mm después del título

          // Agrega la imagen al PDF debajo del título
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          // Maneja el contenido que excede el tamaño de una página
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();

            // Agrega el logo y título en las páginas adicionales si es necesario
            pdf.addImage(logotinet, 'PNG', 150, 10, 50, 15);
            // pdf.text(title, 105, titleMargin, null, null, 'center');

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          pdf.save(`report(${clientValue})_${startDate.getTime()}.pdf`);
        });
      }, 0);
    } else {
      console.error('Elemento .export-pdf no encontrado en el DOM');
    }
  };
  const handleFilter = () => {
    // Validación: startDate y endDate no deben ser vacíos
    if (!startDate || !endDate) {
      openModal('Error de Validación', 'Ambas fechas deben ser seleccionadas.');
      return;
    }

    // Validación: la diferencia entre startDate y endDate no debe ser mayor a 30 días
    const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    if (daysDifference > 30) {
      openModal('Error de Validación', 'La diferencia entre las fechas no debe ser mayor a 30 días.');
      return;
    }

    // Validación de Cronología
    if (startDate > endDate) {
      openModal('Error de Validación', 'La fecha desde no puede ser posterior a la fecha hasta.');
      return;
    }

    // Validación de Fecha Futura o Pasada
    if (endDate > new Date()) {
      openModal('Error de Validación', 'La fecha desde no puede ser una fecha hasta.');
      return;
    }

    // Generar el objeto filtroTO
    const filtroTO = {
      startDate,
      endDate,
      clientValue,
      originValue
    };
    onFilterChange(filtroTO);
  };
  const handleReset = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setClientValue('Todos');
    setOriginValue('Todos');
    const filtroTO = {
      startDate: new Date(),
      endDate: new Date(),
      clientValue: 'Todos',
      originValue: ORIGIN.ALL
    };
    onFilterChange(filtroTO);
  };
  return (
    <div className="p-4 bg-gray-100 report-filter-component">
      {loadingClients ? (
        <div className="flex items-center justify-center mt-4">
          <Orbit size={25} speed={1.5} color="#000000" />
        </div>
      ) : (
        <div className="flex items-center space-x-5">
          {/* Campo Canal */}
          <div className="grow">
            <label htmlFor="canal" className="block text-sm font-medium text-gray-700">
              Cliente
            </label>
            <select
              id="canal"
              value={clientValue}
              onChange={(e) => setClientValue(e.target.value)}
              name="canal"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="Todos">Todos</option>
              {respuestaClientes &&
                respuestaClientes.map((option) => (
                  <option key={option.nombre_cliente} value={option.nombre_cliente}>
                    {option.nombre_cliente}
                  </option>
                ))}
            </select>
          </div>

          {/* Campo origen */}
          <div className="grow">
            <label htmlFor="origen" className="block text-sm font-medium text-gray-700">
              Origen
            </label>
            <select
              id="origen"
              value={originValue}
              onChange={(e) => setOriginValue(e.target.value)}
              name="origen"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option key={ORIGIN.ALL} value={ORIGIN.ALL}>
                Todos
              </option>
              <option key={ORIGIN.ATHENA_COPILOT} value={ORIGIN.ATHENA_COPILOT}>
                Athena Copilot
              </option>
              <option key={ORIGIN.WEB} value={ORIGIN.WEB}>
                Athena Web
              </option>
            </select>
          </div>
          {/* Campo Fecha Inicial */}
          <div className="grow">
            <label htmlFor="fechaInicial" className="block text-sm font-medium text-gray-700">
              Fecha Inicial
            </label>
            <DatePicker
              selected={startDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setStartDate(date)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Campo Fecha Final */}
          <div className="grow">
            <label htmlFor="fechaFinal" className="block text-sm font-medium text-gray-700">
              Fecha Final
            </label>
            <DatePicker
              selected={endDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setEndDate(date)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {/* Botones */}
          <div className="grow justify-end space-x-2">
            <label htmlFor="fechaFinal" className="block text-sm font-medium text-gray-700">
              &nbsp;
            </label>
            <button
              onClick={() => handleFilter()}
              className="py-2 px-4 bg-green-500 text-white rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Filtrar
            </button>
            <button
              onClick={() => handleReset()}
              className="py-2 px-4 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Limpiar
            </button>
            <button
              onClick={handleExport}
              disabled={!isExportable}
              className={`py-2 px-4 ${isExportable ? 'bg-purple-500 hover:bg-purple-600' : 'bg-purple-300'} text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
              Exportar
            </button>
          </div>
        </div>
      )}
      <Modal isOpen={isModalOpen} closeModal={closeModal} title={modalContent.title} message={modalContent.message} />
    </div>
  );
};

export default ReportFilterComponent;
