import React from 'react';
import { TinetLogoSvg } from '../../assets/icons/TinetLogoSvg';
import 'react-toastify/dist/ReactToastify.css';
import { PlusCircle } from '../../assets/icons/PlusCircle';
import './SidebarLayout.css';
import ChatItem from '../../helpers/chatItem';
const SidebarLayout = ({ children, currentThemeParams, onChatClick, reduceSidebar, handleSidebarDimensions }) => {
  // Escucha si sidebar ha sido reducido para ocultar tambien el children que recibe el componente
  const reduce = document.querySelector('.sidebar-changeDimensions.reduce-dimension');
  return (
    <>
      <div className="overflow-y-hidden z-10 sidebar-content sidebar-changeDimensions themeSidebar themeSidebar-mobile">
        <div className="sidebar-content-new-chat themeSidebar-new-chat">
          <ChatItem
            reduceSidebar={reduceSidebar}
            handleSidebarDimensions={handleSidebarDimensions}
            chatName={'Nuevo Chat'}
            chatId={null}
            onChatClick={onChatClick}
            isSelected={false}
            icon={<PlusCircle theme={currentThemeParams} />}
            type="new-chat"
          />
        </div>

        {/** Sidebar version web estandar */}
        <div className="sidebar-content-top">
          <div className="sidebar-content-new-chat-mobile themeSidebar-new-chat">
            <ChatItem
              reduceSidebar={reduceSidebar}
              handleSidebarDimensions={handleSidebarDimensions}
              chatName={'Nuevo Chat'}
              chatId={null}
              onChatClick={onChatClick}
              isSelected={false}
              icon={<PlusCircle theme={currentThemeParams} />}
              type="new-chat-mobile"
            />
          </div>
          <div style={{ display: reduce ? 'none' : 'flex' }} className="children-content">
            {children}
          </div>
        </div>
        <div className="sidebar-content-bottom">
          <div className="h-4 version-info">
            <p className="poppins-regular">Desarrollado y potenciado por</p>
          </div>
          <TinetLogoSvg width={58} height={23} />
        </div>
      </div>
    </>
  );
};

export default SidebarLayout;
