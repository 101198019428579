import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './BarChart.css';

function BarChart({ data, title, labelInput }) {
  if (!data) {
    return null;
  }

  const labels = Object.keys(data).filter(
    (key) => key !== 'total_periodo_consulta_desde' && key !== 'total_periodo_consulta_hasta' && key !== 'reportMontly'
  );

  const values = labels.map((key) => data[key]);
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: labelInput,
        data: values,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const options = {
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 25
        },
        color: '#000000'
      },
      legend: {
        display: true,
        position: 'top'
      }
    },
    scales: {
      x: { type: 'category', title: { display: true, text: 'Metricas' } },
      y: { title: { display: true, text: 'Valor' } }
    }
  };

  return (
    <div>
      <Bar data={chartData} options={options} className="myBarChart" />
    </div>
  );
}

export default BarChart;
