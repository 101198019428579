import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 20,
  height: 20,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const Export = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 18.75C15 19.1642 14.6642 19.5 14.25 19.5L2.25 19.5C1.83579 19.5 1.5 19.1642 1.5 18.75L1.5 5.25C1.5 4.83579 1.83579 4.5 2.25 4.5L14.25 4.5C14.6642 4.5 15 4.83579 15 5.25L15 8.25C15 8.66421 15.3358 9 15.75 9C16.1642 9 16.5 8.66421 16.5 8.25L16.5 5.25C16.5 4.00736 15.4926 3 14.25 3L2.25 3C1.00736 3 7.42772e-07 4.00736 6.88454e-07 5.25L9.83506e-08 18.75C4.4033e-08 19.9926 1.00736 21 2.25 21L14.25 21C15.4926 21 16.5 19.9926 16.5 18.75L16.5 15.75C16.5 15.3358 16.1642 15 15.75 15C15.3358 15 15 15.3358 15 15.75L15 18.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7803 12.5303C24.0732 12.2374 24.0732 11.7626 23.7803 11.4697L19.2803 6.96967C18.9874 6.67678 18.5126 6.67678 18.2197 6.96967C17.9268 7.26256 17.9268 7.73744 18.2197 8.03033L21.4393 11.25L8.25 11.25C7.83579 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.83579 12.75 8.25 12.75L21.4393 12.75L18.2197 15.9697C17.9268 16.2626 17.9268 16.7374 18.2197 17.0303C18.5126 17.3232 18.9874 17.3232 19.2803 17.0303L23.7803 12.5303Z"
    />
  </svg>
);
