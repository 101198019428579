import React from 'react';
import './CodeCopyBtnComponent.css';
import { Clipboard } from '../assets/icons/Clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function CodeCopyBtnComponent({ children, currentThemeParams }) {
  const handleClick = () => {
    navigator.clipboard.writeText(children[0].props.children[0]);
    setTimeout(() => {
      toast.success('Codigo Copiado!');
    }, 500);
  };
  return (
    <div className="code-copy-btn">
      <button onClick={handleClick} className="flex ml-auto gizmo:ml-0 gap-2 items-center">
        <Clipboard alt="Guardar historial" theme={currentThemeParams} /> Copiar código
      </button>
    </div>
  );
}
