import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Hub, Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import SessionHandler from './components/SessionHandler';
import { toast } from 'react-toastify';
import Landing from './pages/Landing';
import Main from './pages/Main';
import TinetReportes from './pages/TinetReportes';
import { ThemeType } from './utils/constants';
import { Athena } from './assets/icons/Athena';
import { Orbit } from '@uiball/loaders';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // currentThemeParams se le pasa el tipo de tema actual seleccionado, sirve para compartirlo entre los componentes y validar
  const [currentThemeParams, setCurrentThemeParams] = useState();

  /**
   * Consulta parametros del theem actual para definir color del loading
   */
  const themeOrbitColor = currentThemeParams === 'dark' ? '#FFFFFF' : '#242424';

  // almacena estado de reduccion del sidebar
  const [reduceSidebar, setReduceSidebar] = useState(false);

  let theme = ThemeType.MODE_TYPE_LIGHT;

  /**
   * Cuando se monta el componente lee el parametro local guardado y ajusta las clases para cambiar de modo
   */
  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    document.body.classList.add(ThemeType.THEME_TYPE_LIGHT);
    localStorage.setItem('theme', theme);

    if (currentTheme === ThemeType.MODE_TYPE_DEFAULT_NULL) {
      document.body.classList.add(ThemeType.THEME_TYPE_LIGHT);
      localStorage.setItem('theme', theme);
    }
    if (currentTheme === ThemeType.MODE_TYPE_DEFAULT_UNDEFINED) {
      document.body.classList.add(ThemeType.THEME_TYPE_LIGHT);
      localStorage.setItem('theme', theme);
    }
    if (currentTheme === ThemeType.MODE_TYPE_DEFAULT_EMPTY) {
      document.body.classList.add(ThemeType.THEME_TYPE_LIGHT);
      localStorage.setItem('theme', theme);
    }
    if (currentTheme === ThemeType.MODE_TYPE_DARK) {
      document.body.classList.remove(ThemeType.THEME_TYPE_LIGHT);
      document.body.classList.add(ThemeType.THEME_TYPE_DARK);
      theme = ThemeType.MODE_TYPE_DARK;
      localStorage.setItem('theme', theme);
    }
    if (currentTheme === ThemeType.MODE_TYPE_LIGHT) {
      document.body.classList.remove(ThemeType.THEME_TYPE_DARK);
      document.body.classList.add(ThemeType.THEME_TYPE_LIGHT);
      localStorage.setItem('theme', theme);
    }
    setCurrentThemeParams(currentTheme);
  }, []);

  /**
   * Lee la clase global de body en index.css para ver si se encuentra en .dark-theme o .light-theme y cambiar entre ellas para definir estilos
   */
  const blackModeTrigger = () => {
    const prefersDarkScheme = document.querySelector('body.dark-theme');
    if (!prefersDarkScheme) {
      setCurrentThemeParams(ThemeType.MODE_TYPE_DARK);
      document.body.classList.remove(ThemeType.THEME_TYPE_LIGHT);
      document.body.classList.toggle(ThemeType.THEME_TYPE_DARK);
      theme = ThemeType.MODE_TYPE_DARK;
      localStorage.setItem('theme', theme);
    } else {
      setCurrentThemeParams(ThemeType.MODE_TYPE_LIGHT);
      document.body.classList.remove(ThemeType.THEME_TYPE_DARK);
      document.body.classList.toggle(ThemeType.THEME_TYPE_LIGHT);
      localStorage.setItem('theme', theme);
    }
  };

  // get user
  async function getUser() {
    try {
      const token = await Auth.currentAuthenticatedUser();
      setLoading(false);
      setUser(token);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }
  // FIX PROD
  // Desregistra todos los Service Workers al cargar la página
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
  //listen for sign in + out events, if neither are happening check if user exists
  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        return getUser();
      }
      if (payload.event === 'signOut') {
        setUser(null);
        return setLoading(false);
      }
    });
    getUser();
  }, []);

  /**
   * Inicio de sesión de google que se le pasa a los botones de ingreso a la app
   */
  const handleSignIn = () => {
    Auth.federatedSignIn({ provider: 'Google' });
  };

  const handleSignOut = () => {
    toast.warning('Saliendo de sesión', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000
    });
    Auth.signOut();
  };

  /**
   * Trigger para setear nuevo estado de reduccion de escapa para sidebar
   */
  const reduceSidebarScale = () => {
    setReduceSidebar(!reduceSidebar);
  };

  /**
   * Modifica estilos de sidebar para efecto de apertura y cierre
   */
  const handleSidebarDimensions = () => {
    document.querySelector('.sidebar-changeDimensions').classList.toggle('reduce-dimension');
    document.querySelector('.main-info-wrapper').classList.toggle('max-imension');
    document.querySelector('.sidebar-content').classList.toggle('resize');
    document.querySelector('.main-chat-item-btn-responsive').classList.toggle('selected');
    document.querySelector('.sidebar-content-bottom').classList.toggle('reduce');
    reduceSidebarScale();
  };

  // show loading screen while fetching, otherwise return page
  if (loading) {
    return (
      <div className="loading-container themeLoadingScreen">
        <Athena theme={currentThemeParams} styles={'loading-logo'} />
        <Orbit size={25} speed={1.5} color={themeOrbitColor} />
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/tinet/reportes"
            element={
              user ? (
                <TinetReportes
                  user={user}
                  handleSidebarDimensions={handleSidebarDimensions}
                  currentThemeParams={currentThemeParams}
                  blackModeTrigger={blackModeTrigger}
                  handleSignOut={handleSignOut}
                />
              ) : (
                <Landing
                  handleSignIn={handleSignIn}
                  currentThemeParams={currentThemeParams}
                  blackModeTrigger={blackModeTrigger}
                />
              )
            }
          />
          <Route
            path="/"
            element={
              user ? (
                <Main
                  user={user}
                  handleSidebarDimensions={handleSidebarDimensions}
                  currentThemeParams={currentThemeParams}
                  blackModeTrigger={blackModeTrigger}
                  handleSignOut={handleSignOut}
                  reduceSidebar={reduceSidebar}
                />
              ) : (
                <Landing
                  handleSignIn={handleSignIn}
                  currentThemeParams={currentThemeParams}
                  blackModeTrigger={blackModeTrigger}
                />
              )
            }
          />
        </Routes>
        <ToastContainer />
        <SessionHandler />
      </div>
    </Router>
  );
}
export default App;
