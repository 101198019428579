import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 84,
  height: 24,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const TinetLogoSvg = ({ width, height, theme, styles }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 84 24"
    fill={changeIconTheme(theme)}
    className={styles}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1667_87)">
      <path
        d="M6.47417 19.0173C5.55215 19.0173 4.8042 18.2704 4.8042 17.3497V10.7202L8.04709 9.40026L7.30863 6.25672H4.80526V0.412109L0.486328 1.43081V17.3529C0.486328 20.8145 3.29669 23.6199 6.76217 23.6199H10.3933L9.21913 19.0173H6.47417Z"
        fill="#006099"
      />
      <path d="M16.4936 6.25684H12.1895V23.62H16.4936V6.25684Z" fill="#006099" />
      <path
        d="M15.5663 0.412109H13.2001C12.6419 0.412109 12.1895 0.863952 12.1895 1.42133V3.78424C12.1895 4.34162 12.6419 4.79346 13.2001 4.79346H15.5663C16.1245 4.79346 16.577 4.34162 16.577 3.78424V1.42133C16.577 0.863952 16.1245 0.412109 15.5663 0.412109Z"
        fill="#006099"
      />
      <path
        d="M27.254 4.67012C25.3425 4.67012 23.8972 5.49709 22.8539 6.69171V4.62061H18.4854V23.3838H22.8539V12.786C22.9657 12.3267 23.1725 11.6883 23.5427 11.0741C24.3445 9.74465 25.5239 9.12521 27.254 9.12521C28.6835 9.12521 29.6698 9.76783 30.3376 10.4768C31.44 11.6462 31.4601 13.2548 31.4527 13.4075L31.4474 13.4665V23.6209L35.9088 21.8458V13.6393C35.9467 12.9303 35.9868 9.64457 33.706 7.18473C32.6427 6.03857 30.6604 4.67117 27.2551 4.67117L27.254 4.67012Z"
        fill="#006099"
      />
      <path
        d="M47.343 4.62061C41.9839 4.62061 37.6396 8.95877 37.6396 14.3104C37.6396 19.662 41.9839 24.0001 47.343 24.0001C51.568 24.0001 55.1601 21.3033 56.4925 17.5392H51.5649C50.5901 18.8002 49.0626 19.6124 47.343 19.6124C46.037 19.6124 44.8407 19.1426 43.9134 18.3641L56.9704 13.1178C56.3817 8.32985 52.297 4.62166 47.343 4.62166V4.62061ZM42.0166 14.5379C42.0124 14.4547 42.0103 14.3715 42.0103 14.2872C42.0103 11.3459 44.3976 8.96193 47.343 8.96193C48.9106 8.96193 50.32 9.6372 51.2959 10.7138L42.0166 14.539V14.5379Z"
        fill="#006099"
      />
      <path
        d="M67.3646 19.0173H64.6196C63.6976 19.0173 62.9496 18.2704 62.9496 17.3497V10.7202L66.1925 9.40026L65.4541 6.25672H62.9507V0.412109L58.6328 1.43081V17.3529C58.6328 20.8145 61.4432 23.6199 64.9087 23.6199H68.5398L67.3656 19.0173H67.3646Z"
        fill="#006099"
      />
      <path
        d="M79.6887 7.69007e-05H75.0586C72.0172 7.69007e-05 69.44 2.23447 69.0127 5.24106L68.5391 8.57843L73.2325 6.58422L73.358 5.70037C73.4498 5.05565 74.0026 4.57632 74.6546 4.57632H78.1243C78.5146 4.57632 78.8142 4.9208 78.7593 5.30637L78.042 10.3577C77.9407 11.073 77.3278 11.604 76.6041 11.604H74.0279C73.896 11.604 73.7695 11.5829 73.6502 11.5471L70.2787 15.7251C70.7639 15.9485 71.3041 16.0738 71.879 16.0738H76.5091C79.5505 16.0738 82.1278 13.8394 82.555 10.8328L83.4749 4.35299C83.8019 2.05433 82.0149 -0.000976562 79.6898 -0.000976562L79.6887 7.69007e-05Z"
        fill="#C41A90"
      />
    </g>
    <defs>
      <clipPath id="clip0_1667_87">
        <rect width="83.027" height="24" fill="white" transform="translate(0.486328)" />
      </clipPath>
    </defs>
  </svg>
);
