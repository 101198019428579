import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 20,
  height: 20,
  themeIcon: ThemeIconColor_enum.LIGHT
};
export const Moon = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 20 20"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50037 0.34788C7.72813 0.625544 7.80587 1.04401 7.60087 1.42016C6.90013 2.70593 6.50188 4.17877 6.50188 5.74607C6.50188 10.7716 10.6004 14.8409 15.6502 14.8409C16.3082 14.8409 16.9493 14.7719 17.5668 14.6411C17.989 14.5516 18.373 14.7425 18.5789 15.0358C18.7913 15.3383 18.8358 15.7897 18.5398 16.1531C16.6292 18.4981 13.706 20 10.4295 20C4.66678 20 0 15.3579 0 9.63753C0 5.33225 2.64268 1.63996 6.40442 0.0751098C6.83905 -0.105689 7.26536 0.0613665 7.50037 0.34788Z" />
  </svg>
);
