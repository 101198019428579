import React from 'react';
import './MainLayout.css';
import NavBar from '../../components/NavBarComponent';
const MainLayout = ({
  children,
  user,
  handleSignIn,
  handleSidebarDimensions,
  blackModeTrigger,
  currentThemeParams,
  handleSignOut
}) => {
  return (
    <>
      <div className="main-layout-container">
        <NavBar
          user={user}
          handleSignIn={handleSignIn}
          handleSidebarDimensions={handleSidebarDimensions}
          blackModeTrigger={blackModeTrigger}
          currentThemeParams={currentThemeParams}
          handleSignOut={handleSignOut}
        />
        <>{children}</>
      </div>
    </>
  );
};

export default MainLayout;
