import { Auth } from 'aws-amplify';

export const SecurityTinetReportsComponent = async (allowedGroups, user) => {
  let mapPermisos = new Map();
  if (!user) {
    return mapPermisos;
  }
  try {
    const userInfo = await Auth.currentAuthenticatedUser();
    const { signInUserSession } = userInfo;
    const groups = signInUserSession.idToken.payload['cognito:groups'];
    allowedGroups.forEach((element) => {
      let permiso = element;
      let tienePermiso = groups.some((group) => permiso.includes(group));
      mapPermisos.set(permiso, tienePermiso);
    });
  } catch (error) {
    console.error('Error en SecurityTinetReportsComponent:', error);
    return new Map();
  }
  return mapPermisos || new Map();
};

export default SecurityTinetReportsComponent;
