import React from 'react';
import './StatisticsBlockComponent.css';

function StatisticsBlock({ data, title, units }) {
  if (!data) {
    return null;
  }
  const StatCard = ({ statName, statValue }) => (
    <div className="stat-card shadow rounded-lg p-4 flex flex-col items-center justify-center text-center h-36">
      {' '}
      {/* Altura fija */}
      <div className="text-xl font-semibold text-gray-800">
        {statValue.toFixed(2)} {units}
      </div>
      <div className="text-sm font-medium text-gray-500 mt-1">{statName}</div>
    </div>
  );

  const StatisticsBlock = ({ stats, title }) => {
    return (
      <div className="container mx-auto p-4 statistics-block">
        {title && <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>} {/* Título */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center justify-center">
          {' '}
          {/* Contenido centrado */}
          {stats.map((stat, index) => (
            <StatCard key={index} statName={stat.name} statValue={stat.value} />
          ))}
        </div>
      </div>
    );
  };
  return (
    <div>
      <StatisticsBlock stats={data} title={title} />
    </div>
  );
}
export default StatisticsBlock;
