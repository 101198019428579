import React from 'react';
import { Bar } from 'react-chartjs-2';
import './HorizontalBarChart.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBarChart = ({ data, title }) => {
  if (!data) {
    return null;
  }
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'Cantidad',
        data: Object.values(data),
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1
      }
    ]
  };

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    scales: {
      x: {
        ticks: {}
      },
      y: {
        ticks: {
          autoSkip: false,
          maxTicksLimit: 50
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right'
      },
      title: {
        display: true,
        text: title
      }
    }
  };

  return (
    <div>
      <Bar data={chartData} options={options} className="horizontal-bar-chart" />
    </div>
  );
};

export default HorizontalBarChart;
