import React, { useState, useEffect } from 'react';
import { Person } from '../assets/icons/Person';
import { AthenaIsotipo } from '../assets/icons/AthenaIsotipo';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import { lucario, dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import CodeCopyBtn from './CodeCopyBtnComponent';
import { ChangeImagecomponent_List_Text, Transition_time } from '../utils/constants';

export default function ChangeImageComponent({ data, currentThemeParams }) {
  const renderers = {
    code: ({ language, value }) => {
      return <pre className={`code-block ${language}`}>{value}</pre>;
    }
  };

  /**
   * Estados para el manejo de transiciones del componente
   */
  const [firstStep, setFirstStep] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);

  /**
   * Interviene en el cambio de estilos y contenido entre la primera iteracion del contenido de la imagen y cuando
   * expande la caja para mostrar la respuesta de athena en el ejemplo.
   */
  useEffect(() => {
    const changeFirst = () => {
      setTimeout(() => {
        setFirstStep(true);
      }, Transition_time.TRESMIL);
    };
    changeFirst();
  }, []);

  /**
   * Reinicia el valor del estado que interviene en la iteracion
   */
  const currentReset = () => {
    setFirstStep(false);
    setCurrentItem(0);
    setTimeout(() => {
      setFirstStep(true);
    }, Transition_time.TRESMIL);
  };

  /**
   * Incrementa el valor del estado para avanzar en la iteracion
   */
  const currentIncrement = () => {
    setFirstStep(false);
    setCurrentItem(currentItem + 1);
    setTimeout(() => {
      setFirstStep(true);
    }, Transition_time.TRESMIL);
  };

  /**
   * Dependiendo del tamaño de data gatilla un aumento del estado para seguir avanzando o lo reinicia.
   */
  const changeCurrent = () => {
    currentItem === data?.length - 1 ? currentReset() : currentIncrement();
  };

  /**
   * Consulta si data contiene mas de un elemento para actualizar la iteracion cada 10seg
   */
  useEffect(() => {
    if (data?.length > 0) {
      setTimeout(() => {
        changeCurrent();
      }, Transition_time.DIEZMIL);
    }
  }, [currentItem]);

  /**
   * Variable que muta dependiendo de validación al recibir componentes
   */
  let validadortheme = null;

  /**
   * Recibe los parametros para pintar el contenido del componente
   * @param {*} item
   * @param {*} itemHtml
   * @returns
   */
  const RenderContent = (item, itemHtml) => {
    const markdownContent = itemHtml ? itemHtml : item;
    const printAllTexts = item && itemHtml;
    validadortheme = printAllTexts;
    return (
      <div className="markdown-container">
        <div style={{ display: printAllTexts ? 'flex' : 'none' }} className="text-code">
          <p>{item}</p>
        </div>
        {printAllTexts ? (
          <div className="markdown-wrapper">
            <ReactMarkdown
              remarkPlugins={[gfm]}
              rehypePlugins={[rehypeRaw]}
              children={markdownContent}
              renderers={renderers}
              components={{
                code({ className, children, ...props }) {
                  className = 'language-html';
                  const match = /language-(\w+)/.exec(className || '');
                  return match ? (
                    <div className="code-style">
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        language={match[1]}
                        style={currentThemeParams === 'dark' ? dracula : lucario}
                        {...props}
                      />
                    </div>
                  ) : (
                    <div className="code-style">
                      <pre>
                        <CodeCopyBtn>{children}</CodeCopyBtn>
                        {children}
                      </pre>
                      <code className={className} {...props}>
                        {children}
                      </code>
                    </div>
                  );
                }
              }}
            />
          </div>
        ) : (
          <div className="change-image-component-list-container">
            <ul className="change-image-component-list-container-ul-lists">
              <li>{ChangeImagecomponent_List_Text?.titulo_general}</li>
              <li>{ChangeImagecomponent_List_Text?.contenido_lista[0].titulo}</li>
              <ul>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[0].portada_item_1}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[0].portada_item_2}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[0].portada_item_3}</li>
              </ul>
              <li>{ChangeImagecomponent_List_Text?.contenido_lista[1].titulo}</li>
              <ul>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[1].intro_item_1}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[1].intro_item_2}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[1].intro_item_3}</li>
              </ul>
              <li>{ChangeImagecomponent_List_Text?.contenido_lista[2].titulo}</li>
              <ul>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[2].cuerpo_item_1}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[2].cuerpo_item_2}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[2].cuerpo_item_3}</li>
                <li>{ChangeImagecomponent_List_Text?.contenido_lista[2].cuerpo_item_4}</li>
              </ul>
              <li>{ChangeImagecomponent_List_Text?.contenido_lista[3].titulo}</li>
            </ul>
          </div>
        )}
      </div>
    );
  };

  /**
   * Recibe parametros para validar combinacion de estilos que tendrá la caja que contiene la información
   * @param {*} step
   * @param {*} theme
   * @returns
   */
  const editCurrentTheme = (step, theme) => {
    let currentBoxtheme = '';
    let anotherTheme = theme
      ? 'img-content-expand themeChangeImageComponent'
      : 'img-content-expand img-content-expand-text themeChangeImageComponent';
    !step ? (currentBoxtheme = 'img-content-init themeChangeImageComponent') : (currentBoxtheme = anotherTheme);
    return currentBoxtheme;
  };

  return (
    <motion.div
      key={data[currentItem]?.id}
      animate={{ opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0] }}
      transition={{ duration: 12 }}
      className={'side-component-change-image'}>
      {/** Maneja la imagen del componente */}
      <motion.div className="img" style={{ backgroundImage: `url(${data[currentItem]?.img})` }} layout />

      {/** DIV MENSAJE */}
      <div className={editCurrentTheme(firstStep, validadortheme)}>
        {/** Consulta si aun no ha dado el primer para cambiar el icono */}
        <div className="icon">
          {!firstStep ? <Person width={24} height={24} /> : <AthenaIsotipo theme={currentThemeParams} />}
        </div>
        {/** Consulta si aun no ha dado el primer salto para cambiar el texto */}
        {!firstStep ? (
          <p>{data[currentItem]?.text_init}</p>
        ) : (
          RenderContent(data[currentItem]?.text_expand, data[currentItem]?.text_html)
        )}
      </div>
    </motion.div>
  );
}
