import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 20,
  height: 20,
  themeIcon: ThemeIconColor_enum.LIGHT
};
export const MenuMobile = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1667 12.9814H0.833333C0.375 12.9814 0 12.5314 0 11.9814C0 11.4314 0.375 10.9814 0.833333 10.9814H19.1667C19.625 10.9814 20 11.4314 20 11.9814C20 12.5314 19.625 12.9814 19.1667 12.9814Z" />
    <path d="M19.1667 5.3125H0.833333C0.375 5.3125 0 4.8625 0 4.3125C0 3.7625 0.375 3.3125 0.833333 3.3125H19.1667C19.625 3.3125 20 3.7625 20 4.3125C20 4.8625 19.625 5.3125 19.1667 5.3125Z" />
    <path d="M9.25 20.6436H0.703125C0.473958 20.6436 0 20.1936 0 19.6436C0 19.0936 0.473958 18.6436 0.703125 18.6436H9.25C9.47917 18.6436 10 19.0936 10 19.6436C10 20.1936 9.47917 20.6436 9.25 20.6436Z" />
  </svg>
);
