import axios from 'axios';
import GetAccessToken from '../services/AuthService';

const UsersChatService = async (username) => {
  // Obtienes el token de acceso
  let access_token = await GetAccessToken();

  // Configuras los headers
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    // Agrega los parámetros aquí, dentro del objeto de configuración
    params: {
      username: username.attributes.email,
      app_environment: process.env.REACT_APP_ENV
    }
  };

  try {
    // La URL del API
    const apiUrl = process.env.REACT_APP_API_USERS_CHATS;
    // Realizas la solicitud GET con la URL y el objeto de configuración
    let response = await axios.get(apiUrl, config);
    return response;
  } catch (error) {
    console.debug('[UsersChatService] Consultando servicio:', error);
    return null;
  }
};

export default UsersChatService;
