import React, { useState, useEffect } from 'react';
import './NavBarComponent.css';
import { ButtonComponent_enum } from '../utils/constants';
import SecurityTinetReportsComponent from '../components/security/SecurityTinetReportsComponent';
import { useLocation } from 'react-router-dom';
import { faChartColumn, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Athena } from '../assets/icons/Athena';
import { Moon } from '../assets/icons/Moon';
import { Sun } from '../assets/icons/Sun';
import { Export } from '../assets/icons/Export';
import { Person } from '../assets/icons/Person';
import { MenuMobile } from '../assets/icons/MenuMobile';
import { ThemeType } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

export default function NavBar({
  user,
  handleSignIn,
  handleSidebarDimensions,
  blackModeTrigger,
  currentThemeParams,
  handleSignOut
}) {
  // Metodo para acceder a la navegacion
  const navigate = useNavigate();
  // Constante para validar permisos para ver estadisticas
  const accesTinet = 'tinet_reports_access';
  const allowedGroups = [accesTinet];
  // Valida si debe imprimir el botón en dark mode
  let printOnDarkMode = currentThemeParams === ThemeType.MODE_TYPE_DARK ? 'flex' : 'none';
  // Valida si debe imprimir el botón en light mode
  let printOnLightMode = currentThemeParams === ThemeType.MODE_TYPE_DARK ? 'none' : 'flex';
  // Valida si debe imprimir el botón cuando el usuario ha iniciado sesión
  let printUserSignIn = user ? 'flex' : 'none';
  // Valida si debe imprimir el botón cuando el usuario no ha iniciado sesión
  let printUserSignOut = user ? 'none' : 'flex';
  // Valida si debe cambiar de clase para el text del nombre del usuario logeado
  let changeThemeText =
    currentThemeParams === ThemeType.MODE_TYPE_DARK
      ? 'user-info-dark poppins-regular'
      : 'user-info-light poppins-regular';

  // Accede al path actual
  const location = useLocation();
  // Estados para validar permisos para ver estadisticas
  const [permiso, setPermiso] = useState(new Map());

  // Variables para validar cambios de iconos y rutas asociadas a sección de estadisticas
  let positionWeb = location?.pathname;
  let validatorPath = positionWeb === '/tinet/reportes' ? '/' : '/tinet/reportes';
  let validatorIcons = positionWeb === '/tinet/reportes';
  let permisos = permiso.get(accesTinet);
  let validatorBurger = permisos && user ? 'flex' : 'none';

  // Consulta permisos al montar el componente
  useEffect(() => {
    let isMounted = true;

    async function fetchPermiso() {
      try {
        const result = await SecurityTinetReportsComponent(allowedGroups, user);
        if (isMounted) {
          setPermiso(result);
        }
      } catch (error) {
        console.error('Error al obtener permisos:', error);
      }
    }

    fetchPermiso();
    return () => {
      isMounted = false;
    };
  }, [user]);

  /**
   * Componente general para botones recibe parametros y valida que debe pintar
   * @param {*} param0
   * @returns
   */
  const ButtonComponent = ({ type, content, clase, display, alt, action }) => {
    /**
     * Recibe type
     * @returns valida parametros y cambia el retorno final para pintar un boton personalizado
     */
    const changeContent = () => {
      let printContent = content;
      switch (type) {
        case 'text' && content === ButtonComponent_enum.INICIAR_SESION_LABEL:
          return ButtonComponent_enum.INICIAR_SESION_LABEL;
        case 'text' && content !== ButtonComponent_enum.INICIAR_SESION_LABEL:
          return printContent;
        case 'icon':
          return printContent;
        default:
          return printContent;
      }
    };
    return (
      <>
        {action ? (
          <button className={clase} style={{ display: display }} alt={alt} onClick={() => action()}>
            {changeContent()}
          </button>
        ) : (
          <div alt={alt} className={clase} style={{ display: display }}>
            {changeContent()}
          </div>
        )}
      </>
    );
  };

  const InfoIcon = () => {
    return (
      <div className="chart-icon">
        {validatorIcons ? (
          <FontAwesomeIcon icon={faArrowLeft} alt="Reportes" className="cursor-pointer" />
        ) : (
          <FontAwesomeIcon icon={faChartColumn} alt="Reportes" className="cursor-pointer" />
        )}
      </div>
    );
  };

  return (
    <div
      className={
        validatorIcons ? 'navbar-container themeNavbar navbar-container-shadow' : 'navbar-container themeNavbar'
      }>
      <div className="logo-container">
        {/** Icono menu hamburguesa */}
        {validatorIcons ? (
          <div className="menu-responsive-btn" />
        ) : (
          <ButtonComponent
            type={'icon'}
            content={<MenuMobile theme={currentThemeParams} />}
            clase={'menu-responsive-btn'}
            display={printUserSignIn}
            alt={'Logo-menu-responsive'}
            action={handleSidebarDimensions}
          />
        )}
        {/** Logo de athena */}
        <Athena theme={currentThemeParams} styles={'logo-athena'} />
        <div className="version-navbar">{process.env.REACT_APP_VERSION}</div>
      </div>
      <div className="buttom-container">
        <div className="buttom-wrapper">
          {/** Icono Info */}
          <ButtonComponent
            type={'icon'}
            content={<InfoIcon theme={currentThemeParams} />}
            clase={currentThemeParams === 'dark' ? 'blackmode-btn-sun' : 'blackmode-btn'}
            display={validatorBurger}
            alt={'Logo-moon'}
            action={() => navigate(validatorPath)}
          />
          {/** Icono luna */}
          <ButtonComponent
            type={'icon'}
            content={<Moon theme={currentThemeParams} />}
            clase={'blackmode-btn'}
            display={printOnLightMode}
            alt={'Logo-moon'}
            action={blackModeTrigger}
          />
          {/** Icono sol */}
          <ButtonComponent
            type={'icon'}
            content={<Sun theme={currentThemeParams} />}
            clase={'blackmode-btn-sun'}
            display={printOnDarkMode}
            alt={'Logo-sun'}
            action={blackModeTrigger}
          />
          {/** Icono export */}
          <ButtonComponent
            type={'icon'}
            content={<Export theme={currentThemeParams} />}
            clase={'export-btn'}
            display={printUserSignIn}
            alt={'Logo-export'}
            action={handleSignOut}
          />
          {/** Icono usuario */}
          <div className="box-wrapper-icon">
            <ButtonComponent
              type={'icon'}
              content={<Person theme={currentThemeParams} />}
              clase={'person-btn'}
              display={printUserSignIn}
              alt={'Logo-person'}
            />
          </div>
          {/** Boton inciar sesión */}
          <ButtonComponent
            type={'text'}
            content={'Iniciar Sesión'}
            clase={'signin-btn'}
            display={printUserSignOut}
            alt={'sign-in-btn'}
            action={handleSignIn}
          />
        </div>
        {/** Texto usuario logeado */}
        <div className="box-wrapper-icon">
          <ButtonComponent
            type={'text'}
            content={user ? user?.attributes?.email : ''}
            clase={changeThemeText}
            display={printUserSignIn}
            alt={'sign-in-btn'}
            action={''}
          />
        </div>
      </div>
    </div>
  );
}
