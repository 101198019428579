import img1 from '../assets/images/imgLanding1.jpeg';
import img2 from '../assets/images/imgLanding2.jpeg';

export const RESPUESTA_LLM_OK = 'OK';
export const RESPUESTA_LLM_NOK = 'ERROR';
export const ORIGIN = {
  ALL: 'Todos',
  WEB: '',
  ATHENA_COPILOT: 'ATHENA_COPILOT'
};

/**
 * Maneja los tipos de temas disponibles
 */
export const ThemeType = {
  MODE_TYPE_LIGHT: 'light',
  MODE_TYPE_DARK: 'dark',
  MODE_TYPE_DEFAULT_NULL: null,
  MODE_TYPE_DEFAULT_UNDEFINED: undefined,
  MODE_TYPE_DEFAULT_EMPTY: '',
  THEME_TYPE_LIGHT: 'light-theme',
  THEME_TYPE_DARK: 'dark-theme'
};

/**
 * Maneja el control de versiones locales
 */
export const CHECK_LOCAL_APP_VERSION = {
  CURRENT_VERSION: 'current-web-version'
};

/**
 * Maneja constantes para cambio de label de botones de navbar
 */
export const ButtonComponent_enum = {
  INICIAR_SESION_LABEL: 'Iniciar Sesión'
};

/**
 * Maneja los tipos de temas disponibles para el helper de los iconos
 */
export const ThemeIconColor_enum = {
  DARK: '#121212',
  LIGHT: '#F7F7F7',
  PURPLE_DEFAULT: '#C41A90'
};

/**
 * Maneja contenido de bajada para landing
 */
export const LandingContent = {
  LANDING_TITLE: '"Una plataforma de inteligencia artificial conversacional al estilo de Tinet."',
  LANDING_TEXT:
    'Athena es tu aliada en el día a día para generar ideas, motivar la creatividad y potenciar la productividad.',
  LANDING_BTN_LABEL: 'Iniciar Sesión'
};

/**
 * Maneja los tiempos para la iteracion del componente ChangeImageComponent
 */
export const Transition_time = {
  TRESMIL: 3000,
  DIEZMIL: 10000
};

/**
 * Contiene obj con el contenido de la etiqueta ul para mostrar a traves de parametros una lista ordenada en segunda transicion de ChangeImageComponent
 */
export const ChangeImagecomponent_List_Text = {
  titulo_general: 'Esquema de un reporte de libro',
  contenido_lista: [
    {
      titulo: 'Portada',
      portada_item_1: 'Título del informe',
      portada_item_2: 'Autor del informe',
      portada_item_3: 'Fecha'
    },
    {
      titulo: 'Introducción',
      intro_item_1: 'Breve resumen del libro',
      intro_item_2: 'Contexto del libro (autor, género, época, etc.)',
      intro_item_3: 'Propósito del informe'
    },
    {
      titulo: 'Cuerpo',
      cuerpo_item_1: 'Argumento o trama',
      cuerpo_item_2: 'Personajes',
      cuerpo_item_3: 'Temas o ideas principales',
      cuerpo_item_4: 'Estilo literario'
    },
    {
      titulo: 'Conclusión'
    }
  ]
};

/**
 * Contiene la lista de elementos que se le pasa a ChangeImageComponents
 */
export const ChangeImagecomponent_List_Html = [
  {
    id: 1,
    img: img1,
    text_init: 'generar un código de un sitio web sencillo',
    text_expand:
      'Para crear un sitio web sencillo, primero necesitarás aprender algún lenguaje de programación y marcas de estilo. A continuación, se presenta un ejemplo básico de cómo crear un sitio web utilizando HTML y CSS. Primero, crea un archivo HTML y escribe el siguiente código:',
    text_html: `HTML: 

      <!DOCTYPE html>
      <html lang="es">
      <head>
        <meta charset="UTF-8">
        <title>Mi sitio web sencillo</title>
      </head>
      </html>
      `
  },
  {
    id: 2,
    img: img2,
    text_init: 'Escribe el esquema de un reporte de libro para ayudarme a comenzar',
    text_expand: `Esquema de un reporte de libro
        Portada
        Título del informe
        Autor del informe
        Fecha
        Introducción
        Breve resumen del libro
        Contexto del libro (autor, género, época, etc.)
        Propósito del informe
        Cuerpo
        Argumento o trama
        Personajes
        Temas o ideas principales
        Estilo literario
        Conclusión`
  }
];

/**
 * Maneja los parametros para OptionsBoxContainer desplegado en tres puntos del chat
 */
export const Position_options_box = {
  TOP: 80,
  LEFT: 60,
  DEFAULT: 0
};

/**
 * Maneja los parametros para guardar en localstorage el array de elementos fijados de la lista de chats
 */
export const Save_local_list = {
  FIXED_LOCAL_LIST_NAME: 'fixed-lists-array'
};

/**
 * Contiene la lista de elementos que se le pasa a ChangeImageComponents
 */
export const NEW_VERSION_DATA = [
  {
    idType: 1,
    date: '15/05/2024',
    features: [
      {
        id: 1,
        title: 'Mejoras en la experiencia de usuario',
        description:
          'Se realiza un rediseño completo de la interfaz de Athena tanto en la versión web como móvil, mejorando la página de inicio, las interfaces de listado de conversaciones, chat, barra de navegación y otras áreas de interacción.'
      },
      {
        id: 2,
        title: 'Mejoras en funcionalidades',
        description:
          'Se implementan mejoras en el comportamiento del chat y  listado de conversaciones, se ajustan microinteracciones y otras funcionalidades con el objetivo de hacer el flujo de las conversaciones más intuitivo y rápido.'
      },
      {
        id: 3,
        title: 'Nuevas funcionalidades',
        description:
          'Ahora es posible fijar, eliminar chats, detener respuestas todo con el objetivo de hacer más práctico el uso de la herramienta.'
      }
    ]
  }
];

/**
 * Define valores de un array
 */
export const INFO_VERSION_LENGTH = {
  ONLY_FEATURES: 1
};
