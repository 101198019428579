import dataMessage from '../typings/dataMessage';
import axios from 'axios';
import GetAccessToken from '../services/AuthService';
import { RESPUESTA_LLM_OK, RESPUESTA_LLM_NOK } from '../utils/constants';
let controller = new AbortController();

/**
 * Metodo que realiza la consulta al modelo y obtiene la respuesta.
 * Tambien actualiza el log de la consulta en el dynamo.
 *
 * @param {string} url - Url de athena.
 * @param {object} body - Request de la llamada al modelo.
 * @param {function} setMessages - Funcion para actualizar el mensaje desde el front.
 * @param {array} messages - Listado de consultas realizadas.
 * @param {object} userMsg - Consulta realizada.
 * @param {function} setSending - Funcion que permite actualizar el loading.
 * @param {string} access_token - Access token de los servicios.
 * @param {string} url_log - Endpoint del lamda que permite almacenar el log de la consulta en dynamo.
 * @param {number} id_registro - ID del registro en dymano.
 */
const fetchData = async (url, body, setMessages, messages, userMsg, setSending, access_token, url_log, id_registro) => {
  let inicio_consulta = new Date();
  let fin_consulta;
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    onDownloadProgress: (progressEvent) => {
      try {
        if (progressEvent.currentTarget.status !== 200) {
          setMessages([...messages, userMsg, dataMessage('assistant', '')]);
          // Lanza una excepción con el mensaje de error.
          throw new Error('Error al ejecutar consulta a Athena.');
        } else {
          const textChunk = progressEvent.currentTarget.responseText;
          setMessages([...messages, userMsg, dataMessage('assistant', textChunk)]);
        }
      } catch (error) {
        console.error(JSON.stringify(error));
        if (error.response && error.response.data) {
          throw new Error('[ChatService] Error generico');
        } else if (error.message === 'RespuestaVacia') {
          throw new Error('[ChatService] Tamaño de mensaje excedido. Corrige e intenta nuevamente' + error);
        } else {
          throw new Error(
            '[ChatService] Se produjo un error al enviar la pregunta. Por favor, inténtalo nuevamente.' + error
          );
        }
      }
    },
    signal: controller.signal
  };
  try {
    let respuestaLlm = await axios.post(url, body, config);
    fin_consulta = new Date();
    // Llamada al servicio de log exitosa.
    guardar_logs(
      url_log,
      respuestaLlm.data,
      id_registro,
      inicio_consulta,
      fin_consulta,
      RESPUESTA_LLM_OK,
      access_token
    );
    setSending(false);
  } catch (error) {
    // Llamada al servicio de log erronea.
    fin_consulta = new Date();
    console.error('[ChatService][fetchData] Error ejecutando consulta a Athena.' + error);
    guardar_logs(url_log, 'ERROR', id_registro, inicio_consulta, fin_consulta, RESPUESTA_LLM_NOK, access_token);
  }
};

/**
 * Metodo que permite actualizar el registro en el dynamo.
 *
 * @param {string} url_log - Url del servicio que almacena los logs.
 * @param {string} respuesta - Respuesta de la consulta al modelo.
 * @param {number} id_registro - ID del registro a actualizar.
 * @param {Date} inicio_consulta - Hora de inicio de la consulta.
 * @param {Date} fin_consulta - Hora de fin de la consulta.
 * @param {string} estado - Estado de la consulta a guardar.
 * @param {string} access_token - Access token de los servicios.
 */
const guardar_logs = async (url_log, respuesta, id_registro, inicio_consulta, fin_consulta, estado, access_token) => {
  if (id_registro === 0) {
    console.error('[ChatService][guardar_logs] Error ejecutando servicio de almacenado de consulta.');
    return false;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    }
  };
  let request_log = {
    id: id_registro,
    response: respuesta,
    response_time_start: inicio_consulta.getTime(),
    response_time_end: fin_consulta.getTime(),
    response_status: estado
  };
  try {
    await axios.post(url_log, request_log, config);
    return true;
  } catch (error) {
    console.error('[ChatService][guardar_logs] Error guardando logs:', error);
    return false;
  }
};

const guardar_registro = async (url_reg, conversation, access_token, email, chat_id) => {
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
      'X-APP-Version': process.env.REACT_APP_VERSION,
      'X-APP-Business': 'TInet', //TODO: Generar variable de contexto
      'X-APP-Environment': process.env.REACT_APP_ENV
    }
  };
  let request = { messages: conversation, username: email, chat_id: chat_id };
  let idRegistro = 0;
  let chatId = '';
  try {
    let respuestaReg = await axios.post(url_reg, request, config);
    idRegistro = respuestaReg.data.id;
    chatId = respuestaReg.data.chat_id;
  } catch (error) {
    console.error('[ChatService][guardar_registro] Error guardando registro inicial.', error);
  }
  return { idRegistro, chatId };
};

/**
 * ChatService es una función asíncrona que maneja la comunicación con la API.
 * @param {Array} messages - Un array que contiene los mensajes del chat.
 * @param {string} prompt - El mensaje que el usuario ingresa.
 * @param {function} setMessages - Una función para actualizar el estado de los mensajes.
 * @param {function} setSending - Una función para actualizar el estado del envío.
 * @param {Object} user - Un objeto que contiene información del usuario.
 * @param {string} chat_id - El ID del chat actual.
 * @param {Object} sourceCancel - Un objeto que permite cancelar la solicitud en curso a la API.
 * @returns {string} chatId - El ID del chat actualizado.
 */
export const ChatService = async (messages, prompt, setMessages, setSending, user, chat_id) => {
  try {
    let conversation;
    let userMsg;
    let access_token = await GetAccessToken();
    // Check if the conversation is empty or the first message
    if (messages.length === 0) {
      // Add the system message
      const systemMsg = dataMessage('system', 'You are Athena, the first and best assistant by TINET.');
      messages = [systemMsg];
    }

    // Add the user message
    userMsg = dataMessage('user', prompt);
    setMessages([...messages, userMsg, dataMessage('assistant', '')]);
    conversation = [...messages, userMsg];

    let { idRegistro, chatId } = await guardar_registro(
      process.env.REACT_APP_API,
      conversation,
      access_token,
      user.attributes.email,
      chat_id
    );

    // Make the API request
    await fetchData(
      process.env.REACT_APP_API_STREAM,
      conversation,
      setMessages,
      messages,
      userMsg,
      setSending,
      access_token,
      process.env.REACT_APP_API_LOG,
      idRegistro
    );
    return chatId;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data) {
      throw new Error('[ChatService] Error generico');
    } else if (error.message === 'RespuestaVacia') {
      throw new Error('[ChatService] Tamaño de mensaje excedido. Corrige e intenta nuevamente');
    } else {
      throw new Error('[ChatService] Se produjo un error al enviar la pregunta. Por favor, inténtalo nuevamente.');
    }
  }
};

/**
 * Función que permite cancelar una solicitud en curso a la API.
 *
 * @returns {void}
 */
export const CancelRequest = () => {
  controller.abort();
  controller = new AbortController();
};

/**
 * Metodo que permite eliminar chat usuario
 *
 * @param {number} id_registro - ID del registro a actualizar.
 * @param {string} access_token - Access token de los servicios.
 */
export const eliminarChat = async (id_registro) => {
  let access_token = await GetAccessToken();
  if (id_registro === 0) {
    console.error('[ChatService][eliminar] Error ejecutando eliminar chat.');
    return false;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    }
  };

  let request = {
    chat_id: id_registro,
    app_environment: process.env.REACT_APP_ENV
  };

  try {
    return await axios.post(process.env.REACT_APP_API_ELIMINAR_CHAT, request, config);
  } catch (error) {
    console.error('[ChatService] Error eliminando chat:', error);
    return false;
  }
};

export default ChatService;
