export const handleDownloadMessages = (messages, toast) => {
  if (messages.length === 0) {
    toast.warn('No hay historial de mensajes para descargar');
    return;
  }

  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  const fileName = `Athena_chat_${timestamp}.md`;

  const markdownContent = messages
    .map((message) => {
      let content = '';

      switch (message.role) {
        case 'system':
          content += `__SYSTEM__:\n${message.content}\n\n---\n`;
          break;
        case 'user':
          content += `__USER__:\n${message.content}\n`;
          break;
        case 'assistant':
          content += `__AI__:\n${message.content}\n\n---\n`;
          break;
        default:
          break;
      }

      return content;
    })
    .join('\n');

  const markdown = `# Athena - export\n\nChat exportado ${timestamp}\n\n${markdownContent}`;

  const file = new Blob([markdown], { type: 'text/markdown' });
  const fileURL = URL.createObjectURL(file);
  const tempLink = document.createElement('a');
  tempLink.href = fileURL;
  tempLink.setAttribute('download', fileName);

  tempLink.addEventListener('load', () => {
    URL.revokeObjectURL(fileURL);
    toast.success('Historial de mensajes descargado');
  });

  tempLink.click();
};
