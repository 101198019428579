import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { AthenaIsotipo } from '../assets/icons/AthenaIsotipo';
import { Person } from '../assets/icons/Person';
import './MessageComponent.css';
import { coldarkCold, vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CodeCopyBtn from './CodeCopyBtnComponent';
import FlatButtom from '../helpers/flatButtom';
import { Like } from '../assets/icons/Like';
import { Dislike } from '../assets/icons/Dislike';
import { Clipboard } from '../assets/icons/Clipboard';
import { Shared } from '../assets/icons/Shared';

const MessageComponent = ({ role, content, currentThemeParams, handleCopyClick, downloadMessages, sending }) => {
  /**
   * Se ocupa para ocultar los botones en esta etapa
   */
  let visibleBtns = false;

  const imageSource =
    role === 'user' ? (
      <Person width={20} height={20} />
    ) : (
      <AthenaIsotipo width={17} height={48} theme={currentThemeParams} />
    );
  const botonCopiar = ({ children }) => (
    <pre className="blog-pre mt-1 copy-code-btn roboto-regular">
      <CodeCopyBtn currentThemeParams={currentThemeParams}>{children}</CodeCopyBtn>
      {children}
    </pre>
  );
  const renderers = {
    code: ({ language, value }) => {
      return <pre className={`code-block ${language}`}>{value}</pre>;
    }
  };
  return (
    <div
      className={
        role !== 'user'
          ? 'content-ia themeChatMain-ia roboto-regular'
          : 'content-user themeChatMain-user poppins-regular'
      }>
      {content && <div className="w-4 h-4 rounded-full flex-shrink-0 mr-4">{imageSource}</div>}
      <div className="markdown-container">
        {role === 'user' ? (
          <div>
            <p>{content}</p>
          </div>
        ) : (
          <div className="markdown-format">
            <ReactMarkdown
              remarkPlugins={[gfm]}
              rehypePlugins={[rehypeRaw]}
              children={content}
              renderers={renderers}
              components={{
                pre: botonCopiar,
                code({ className, children, ...props }) {
                  className = 'language-java';
                  const match = /language-(\w+)/.exec(className || '');
                  return match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      language={match[1]}
                      style={currentThemeParams === 'dark' ? vscDarkPlus : coldarkCold}
                      {...props}
                    />
                  ) : (
                    <div>
                      <pre className="blog-pre mt-1">
                        <CodeCopyBtn currentThemeParams={currentThemeParams}>{children}</CodeCopyBtn>
                        {children}
                      </pre>
                      <code className={className} {...props}>
                        {children}
                      </code>
                    </div>
                  );
                }
              }}
            />
            <div className="flat-buttom-container">
              {visibleBtns && <FlatButtom buttomIcon={<Like theme={currentThemeParams} />} />}
              {visibleBtns && <FlatButtom buttomIcon={<Dislike theme={currentThemeParams} />} />}
              {visibleBtns && (
                <FlatButtom
                  buttomIcon={<Clipboard theme={currentThemeParams} />}
                  action={() => handleCopyClick(content)}
                />
              )}
              {!sending && <FlatButtom buttomIcon={<Shared theme={currentThemeParams} />} action={downloadMessages} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageComponent;
