import { Auth } from 'aws-amplify';

const GetAccessToken = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return accessToken;
  } catch (error) {
    console.error('Error fetching access token', error);
  }
};

export default GetAccessToken;
