import axios from 'axios';
import GetAccessToken from '../services/AuthService';

const ReportClientsChatService = async () => {
  console.debug('[ReportClientsChatService] Inicio.');
  let access_token = await GetAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    }
  };
  try {
    let response = await axios.get(process.env.REACT_APP_API_CLIENTS, config);
    console.debug('[ReportClientsChatService] Fin.');
    return response;
  } catch (error) {
    console.debug('[ReportClientsChatService] Consultando servicio:', error);
    return null;
  }
};
export default ReportClientsChatService;
