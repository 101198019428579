import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 24,
  height: 24,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const Send = ({ width, height, theme, styles }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    className={styles}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M23.7802 0.219692C23.9923 0.431839 24.0577 0.750002 23.9462 1.02856L15.218 22.8492C14.8775 23.7004 13.7165 23.8088 13.2243 23.0354L8.4566 15.5433L0.964496 10.7756C0.191073 10.2834 0.299484 9.1224 1.15067 8.78193L22.9713 0.0536643C23.2499 -0.0577607 23.5681 0.00754483 23.7802 0.219692ZM9.95562 15.1049L14.097 21.6128L21.1965 3.86408L9.95562 15.1049ZM20.1358 2.80342L2.38709 9.90291L8.89496 14.0443L20.1358 2.80342Z" />
  </svg>
);
