import React from 'react';
import { Athena } from '../../assets/icons/Athena';
import { TinetLogoSvg } from '../../assets/icons/TinetLogoSvg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBug, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function NewVersionInfo({
  displayVersion,
  currentThemeParams,
  currentVersion,
  data,
  saveCurrentVersion,
  themeIconFeaturesColor,
  expandFeatures,
  expandBugs,
  handleExpandFeatures,
  handleExpandBugs
}) {
  /**
   * Caja que envuelve los elementos del arreglo en los que se está iterando.
   * @param {*} boxType - tipo de elemento a iterar
   * @param {*} boxValidator - estado que manejará las condiciones de la caja
   * @param {*} boxTitle - titulo principal de la caja
   * @param {*} action - trigger de botón expandir y reducir tamaño de caja
   * @param {*} icon - icono que acompaña al titulo de cada elemento
   * @returns
   */
  const BoxExpand = ({ boxType, boxValidator, boxTitle, action, icon }) => {
    return (
      <div
        className="new-version-info-wrapper-bot-content-top themeNewVersionInfo-sections"
        style={{ height: boxValidator ? 'auto' : 60 }}>
        <div className="new-version-info-wrapper-bot-content-top-siwtch-btn">
          <h1 className="roboto-regular">{boxTitle}</h1>
          <button onClick={action}>
            {boxValidator ? (
              <FontAwesomeIcon icon={faChevronUp} alt="Reportes" className={themeIconFeaturesColor} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} alt="Reportes" className={themeIconFeaturesColor} />
            )}
          </button>
        </div>
        <ul style={{ display: boxValidator ? 'flex' : 'none' }}>
          <div>
            {boxType.map((item) => (
              <div key={item.id}>
                <div className="new-version-info-wrapper-bot-item">
                  <li>
                    <div className="new-version-info-wrapper-bot-item-title">
                      <FontAwesomeIcon icon={icon} alt="Reportes" className={themeIconFeaturesColor} />
                      <h1 className="roboto-regular">{item?.title}</h1>
                    </div>
                    <p>{item?.description}</p>
                  </li>
                </div>
              </div>
            ))}
          </div>
        </ul>
      </div>
    );
  };

  return (
    /**
     * Caja que envuelve los elementos del arreglo en los que se está iterrando.
     * @param {*} boxType - tipo de elemento a iterar
     * @param {*} boxValidator - estado que manejará las condiciones de la caja
     * @param {*} boxTitle - titulo principal de la caja
     * @param {*} action - trigger de botón expandir y reducir tamaño de caja
     * @param {*} icon - icono que acompaña al titulo de cada elemento
     * @returns
     */
    <div className="new-version-info-container" style={{ display: displayVersion }}>
      <div className="new-version-info-wrapper themeNewVersionInfo">
        <div className="new-version-info-wrapper-top">
          <Athena theme={currentThemeParams} />
          <TinetLogoSvg />
        </div>
        <h1 className="section-title roboto-regular">
          Release: v{currentVersion} ({data[0]?.date})
        </h1>
        <div className="new-version-info-wrapper-bot">
          {data.map((elements) => (
            <div key={elements.idType} className="box-wrapper">
              <div className="box-wrapper">
                {elements?.features && (
                  <BoxExpand
                    boxType={elements?.features}
                    boxValidator={expandFeatures}
                    boxTitle={'Features'}
                    action={handleExpandFeatures}
                    icon={faCheck}
                  />
                )}
              </div>
              <div className="box-wrapper">
                {elements?.bugs && (
                  <BoxExpand
                    boxType={elements?.bugs}
                    boxValidator={expandBugs}
                    boxTitle={'Bugs'}
                    action={handleExpandBugs}
                    icon={faBug}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <button onClick={saveCurrentVersion}>
          <p>cerrar</p>
        </button>
      </div>
    </div>
  );
}
