import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 24,
  height: 24,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const ThreeOptionsVertical = ({ width, height, theme, styles }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    className={styles}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 19.5C14.25 20.7426 13.2426 21.75 12 21.75C10.7574 21.75 9.75 20.7426 9.75 19.5C9.75 18.2574 10.7574 17.25 12 17.25C13.2426 17.25 14.25 18.2574 14.25 19.5ZM14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12ZM14.25 4.5C14.25 5.74264 13.2426 6.75 12 6.75C10.7574 6.75 9.75 5.74264 9.75 4.5C9.75 3.25736 10.7574 2.25 12 2.25C13.2426 2.25 14.25 3.25736 14.25 4.5Z" />
  </svg>
);
