import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 17,
  height: 16,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const Shared = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 17 16"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0381 1C13.2097 1 12.5381 1.67157 12.5381 2.5C12.5381 3.32843 13.2097 4 14.0381 4C14.8665 4 15.5381 3.32843 15.5381 2.5C15.5381 1.67157 14.8665 1 14.0381 1ZM11.5381 2.5C11.5381 1.11929 12.6574 0 14.0381 0C15.4188 0 16.5381 1.11929 16.5381 2.5C16.5381 3.88071 15.4188 5 14.0381 5C13.2791 5 12.5992 4.66182 12.1407 4.12792L5.42307 7.2482C5.49779 7.48549 5.53809 7.73804 5.53809 8C5.53809 8.26196 5.49779 8.51451 5.42307 8.7518L12.1407 11.8721C12.5992 11.3382 13.2791 11 14.0381 11C15.4188 11 16.5381 12.1193 16.5381 13.5C16.5381 14.8807 15.4188 16 14.0381 16C12.6574 16 11.5381 14.8807 11.5381 13.5C11.5381 13.238 11.5784 12.9855 11.6531 12.7482L4.93547 9.62792C4.47698 10.1618 3.79703 10.5 3.03809 10.5C1.65737 10.5 0.538086 9.38071 0.538086 8C0.538086 6.61929 1.65737 5.5 3.03809 5.5C3.79703 5.5 4.47698 5.83818 4.93547 6.37208L11.6531 3.2518C11.5784 3.01451 11.5381 2.76196 11.5381 2.5ZM3.03809 6.5C2.20966 6.5 1.53809 7.17157 1.53809 8C1.53809 8.82843 2.20966 9.5 3.03809 9.5C3.86651 9.5 4.53809 8.82843 4.53809 8C4.53809 7.17157 3.86651 6.5 3.03809 6.5ZM14.0381 12C13.2097 12 12.5381 12.6716 12.5381 13.5C12.5381 14.3284 13.2097 15 14.0381 15C14.8665 15 15.5381 14.3284 15.5381 13.5C15.5381 12.6716 14.8665 12 14.0381 12Z" />
  </svg>
);
