import React from 'react';
import './Landing.css';
import MainLayout from './layouts/MainLayout';
import { AthenaText } from '../assets/icons/AthenaText';
import { LandingContent, ChangeImagecomponent_List_Html } from '../utils/constants';
import ChangeImageComponent from '../components/ChangeImageComponent';

const Landing = ({ handleSignIn, currentThemeParams, blackModeTrigger }) => {
  return (
    <MainLayout
      user={null}
      handleSignIn={handleSignIn}
      currentThemeParams={currentThemeParams}
      blackModeTrigger={blackModeTrigger}>
      <div className="landing-container themeLanding">
        <div className="wrapper-side-content">
          <div className="side-content">
            <AthenaText theme={currentThemeParams} styles={'logo-athena-text'} />
            <h1 className="roboto-regular">{LandingContent.LANDING_TITLE}</h1>
            <p className="poppins-regular">{LandingContent.LANDING_TEXT}</p>
            <button className="signin-btn" onClick={handleSignIn}>
              {LandingContent.LANDING_BTN_LABEL}
            </button>
          </div>
        </div>

        {/** Container para componente de imagenes */}
        <div className="wrapper-side-component-change-image">
          <ChangeImageComponent data={ChangeImagecomponent_List_Html} currentThemeParams={currentThemeParams} />
        </div>
      </div>
    </MainLayout>
  );
};

export default Landing;
