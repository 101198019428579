import { ThemeIconColor_enum, ThemeType } from '../utils/constants';
/**
 * Recibe parametro de modo seleccionado enviado desde el icono al recibirlo por props
 * @param {*} props currentThemeParams
 * @returns devuelve color definitivo con el que renderizará el icono luego de validar el parametro previo para intercambiar los modos
 */
export default function changeIconTheme(props) {
  let propsTheme = ThemeIconColor_enum.LIGHT;
  if (props === ThemeType.MODE_TYPE_LIGHT) {
    propsTheme = ThemeIconColor_enum.DARK;
  }
  return propsTheme;
}
