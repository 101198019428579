import axios from 'axios';
import GetAccessToken from '../services/AuthService';

const UsersChatPromptService = async (chat_id) => {
  console.debug('[UsersChatPromptService] Inicio.');

  // Obtienes el token de acceso
  let access_token = await GetAccessToken();

  // Configuras los headers
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    },
    // Agrega los parámetros aquí, dentro del objeto de configuración
    params: {
      chat_id: chat_id,
      app_environment: process.env.REACT_APP_ENV
    }
  };

  try {
    // La URL del API
    const apiUrl = process.env.REACT_APP_API_USERS_CHATS_PROMPT;
    // Realizas la solicitud GET con la URL y el objeto de configuración
    let response = await axios.get(apiUrl, config);
    console.debug('[UsersChatPromptService] Fin.');
    return response;
  } catch (error) {
    console.debug('[UsersChatPromptService] Consultando servicio:', error);
    return null;
  }
};

export default UsersChatPromptService;
