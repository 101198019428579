import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 17,
  height: 16,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const Clipboard = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 17 16"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M3.96666 1.5H2.8238C1.56144 1.5 0.538086 2.39543 0.538086 3.5V14C0.538086 15.1046 1.56144 16 2.8238 16H14.2524C15.5147 16 16.5381 15.1046 16.5381 14V3.5C16.5381 2.39543 15.5147 1.5 14.2524 1.5H13.1095V2.5H14.2524C14.8836 2.5 15.3952 2.94772 15.3952 3.5V14C15.3952 14.5523 14.8836 15 14.2524 15H2.8238C2.19262 15 1.68094 14.5523 1.68094 14V3.5C1.68094 2.94772 2.19262 2.5 2.8238 2.5H3.96666V1.5Z" />
    <path d="M10.2524 1C10.568 1 10.8238 1.22386 10.8238 1.5V2.5C10.8238 2.77614 10.568 3 10.2524 3H6.8238C6.50821 3 6.25237 2.77614 6.25237 2.5V1.5C6.25237 1.22386 6.50821 1 6.8238 1H10.2524ZM6.8238 0C5.87703 0 5.10951 0.671573 5.10951 1.5V2.5C5.10951 3.32843 5.87703 4 6.8238 4H10.2524C11.1991 4 11.9667 3.32843 11.9667 2.5V1.5C11.9667 0.671573 11.1991 0 10.2524 0H6.8238Z" />
  </svg>
);
