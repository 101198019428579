import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

function SessionHandler() {
  // eslint-disable-next-line no-unused-vars
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    async function checkSession() {
      try {
        const session = await Auth.currentSession();
        const isTokenExpired = session.getIdToken().getExpiration() < Math.floor(new Date().getTime() / 1000);
        if (isTokenExpired) {
          setIsSessionValid(false);
          // Borrar caches si es posible
          if ('caches' in window) {
            caches.keys().then((names) => {
              for (let name of names) caches.delete(name);
            });
          }
          // Recargar página
          Auth.signOut();
        } else {
          setIsSessionValid(true);
        }
      } catch (error) {
        console.error('Error al verificar la sesión:', error);
      }
    }

    checkSession();

    // Opcional: Verifica la sesión en intervalos regulares (por ejemplo, cada minuto)
    const intervalId = setInterval(checkSession, process.env.REACT_APP_SESSION_TIME);

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
  }, []);

  return <div></div>;
}

export default SessionHandler;
