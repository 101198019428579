import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 24,
  height: 24,
  themeIcon: ThemeIconColor_enum.LIGHT
};

export const ChatTexts = ({ width, height, theme, styles }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    className={styles}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.01683 17.8409C4.33242 18.1574 4.49045 18.5981 4.44785 19.0431C4.35239 20.0401 4.13253 21.0778 3.85016 22.0417C5.94249 21.5581 7.22036 20.9972 7.80122 20.7028C8.13093 20.5357 8.51072 20.4963 8.86774 20.592C9.8545 20.8566 10.9061 21 12 21C17.9934 21 22.5 16.7887 22.5 12C22.5 7.21132 17.9934 3 12 3C6.0066 3 1.5 7.21132 1.5 12C1.5 14.2021 2.42596 16.2456 4.01683 17.8409ZM3.27734 23.6984C3.26724 23.7004 3.25712 23.7024 3.24699 23.7044C3.10925 23.7315 2.96883 23.7583 2.82573 23.7845C2.62499 23.8212 2.41896 23.857 2.20758 23.8916C1.90906 23.9405 1.68055 23.6282 1.79847 23.3496C1.87551 23.1676 1.95146 22.9769 2.02545 22.7791C2.07246 22.6535 2.11867 22.525 2.16388 22.3941C2.16583 22.3885 2.16777 22.3828 2.16972 22.3772C2.54133 21.2976 2.84395 20.0567 2.95468 18.9001C1.11459 17.0548 0 14.6413 0 12C0 6.20101 5.37258 1.5 12 1.5C18.6274 1.5 24 6.20101 24 12C24 17.799 18.6274 22.5 12 22.5C10.7749 22.5 9.59262 22.3394 8.47926 22.0408C7.70005 22.4357 6.02151 23.1545 3.27734 23.6984Z" />
    <path d="M6 8.25C6 7.83579 6.33579 7.5 6.75 7.5H17.25C17.6642 7.5 18 7.83579 18 8.25C18 8.66421 17.6642 9 17.25 9H6.75C6.33579 9 6 8.66421 6 8.25ZM6 12C6 11.5858 6.33579 11.25 6.75 11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H6.75C6.33579 12.75 6 12.4142 6 12ZM6 15.75C6 15.3358 6.33579 15 6.75 15H12.75C13.1642 15 13.5 15.3358 13.5 15.75C13.5 16.1642 13.1642 16.5 12.75 16.5H6.75C6.33579 16.5 6 16.1642 6 15.75Z" />
  </svg>
);
