import React, { useState, useEffect } from 'react';
import './Main.css';
import ChatComponent from '../components/ChatComponent';
import MainLayout from './layouts/MainLayout';
import SidebarLayout from './layouts/SidebarLayout';
import dataMessage from '../typings/dataMessage';
import ChatList from '../components/UsersChatList';
import UsersChatService from '../services/UsersChatService';
import UsersChatPromptService from '../services/UsersChatPromptService';
import NewVersionInfo from '../components/commons/NewVersionInfo';
import { Orbit } from '@uiball/loaders';
import { ThemeType, NEW_VERSION_DATA, INFO_VERSION_LENGTH, CHECK_LOCAL_APP_VERSION } from '../utils/constants';
import as from '../../package.json';

const Main = ({
  user,
  handleSidebarDimensions,
  blackModeTrigger,
  currentThemeParams,
  handleSignOut,
  reduceSidebar,
  handleCopyClick
}) => {
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState('');
  const [loadingUserChats, setLoadingUserChats] = useState(false);
  const [respuestaUserChats, setRespuestaUserChats] = useState(null);
  const [showWelcome, setShowWelcome] = useState(true);

  /**
   * Consulta el tamaño de NEW_VERSION_DATA para saber si contiene mas de un arreglo dentro y pintar la primera lista expandida si no tiene algo mas
   * que mostrar.
   * @returns
   */
  function ChangeExpandDimensions() {
    const checkVersionData = Object.entries(NEW_VERSION_DATA).length;
    let size = false;
    if (checkVersionData === INFO_VERSION_LENGTH.ONLY_FEATURES) {
      size = true;
    }
    return size;
  }
  /**
   * Estados para controlar el manejo de dimensiones de contenedores de features y bugs.
   */
  const [expandFeatures, setExpandFeatures] = useState(ChangeExpandDimensions);
  const [expandBugs, setExpandBugs] = useState(false);

  /**
   * Constante asignada con el valor de la version actual que figura en package.json
   */
  const currentVersion = as.version;
  /**
   * Estado que maneja los cambios locales para definir si se imprime o no la ventana de información de features de la versión.
   */
  const [displayVersion, setDisplayVersion] = useState('none');
  /**
   * Consulta current-web-version local, luego la compara con la currentVersion del proyecto, si no son compatibles reasigna el estado para pintar
   * entana de información de features de la versión.
   */
  useEffect(() => {
    const checkLocalVersion = localStorage.getItem(CHECK_LOCAL_APP_VERSION.CURRENT_VERSION);
    if (checkLocalVersion !== currentVersion) {
      setDisplayVersion('flex');
    }
  }, [displayVersion, currentVersion]);

  /**
   * Trigger que setea localmente la version actual del proyecto y reasigna el estado para cerrar la ventana de informacion al presionar "cerrar".
   */
  const saveCurrentVersion = () => {
    localStorage.setItem(CHECK_LOCAL_APP_VERSION.CURRENT_VERSION, currentVersion);
    setDisplayVersion('none');
  };

  /**
   * Consulta parametros del theem actual para definir color del loading
   */
  const themeOrbitColor = currentThemeParams === ThemeType.MODE_TYPE_DARK ? '#FFFFFF' : '#242424';

  /**
   * Consulta parametros del theem actual para definir color del icono para lista de features.
   */
  const themeIconFeaturesColor =
    currentThemeParams === ThemeType.MODE_TYPE_DARK
      ? 'new-version-info-wrapper-bot-item-icon-dark'
      : 'new-version-info-wrapper-bot-item-icon-light';

  /**
   * Estados para manejo de estilos en li y opciones desplegables en boton de tres puntos
   */
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [skeletonNewChat, setSkeletonNewChat] = useState(false);

  /**
   * Recibe parametro para controlar impresión del skeleton de carga de nuevo chat
   * @param {*} params - booleano
   */
  const showSkeleton = (params) => {
    if (!selectedChatId) {
      setSkeletonNewChat(params);
    }
  };

  /**
   * Controla el desplazamiento del scroll cuando se ha seleccionado el boton de tres puntos en la lista del chat
   */
  const cancelScroll = () => {
    document.querySelector('.chat-list')?.classList.toggle('stop');
  };
  const showScroll = () => {
    document.querySelector('.chat-list')?.classList.remove('stop');
  };

  useEffect(() => {
    if (selectedOptions) {
      cancelScroll();
    } else {
      showScroll();
    }
  }, [selectedOptions]);

  /**
   * recibe id para setear estados que controlan estilos
   * @param {*} item
   */
  const handleOptionsClick = (item) => {
    if (selectedOptions !== item) {
      setSelectedOptions(null);
      setTimeout(() => {
        setSelectedOptions(item);
      }, 300);
    } else if (selectedOptions === item) {
      setSelectedOptions(null);
    }
  };

  /**
   * recibe parametros id y name para setear estados que controlan estilos y funcionalidad
   * @param {*} item
   */
  const handleChatId = (id) => {
    setSelectedChatId(id);
    handleChatClick(id);
  };

  /**
   * Accionador para setear showWelcome y validar si se muestra componente de bienvenida o no en main
   * @param {*} param
   */
  const showWelcomeTrigger = (param) => {
    setShowWelcome(param);
  };

  /**
   * reinicia los estados para devolver estilos a default
   * @param {*} item
   */
  const resetStates = () => {
    setSelectedChatId(null);
    setSelectedOptions(null);
    handleChatClick();
  };

  const handleChatClick = async (id) => {
    showWelcomeTrigger(true);
    if (!id) {
      setChatId('');
      setMessages([]);
      return;
    }
    const respuestaPromts = await UsersChatPromptService(id);
    if (respuestaPromts && respuestaPromts.data) {
      let newMesgAsist = dataMessage('assistant', respuestaPromts.data.respuesta[0].response);
      let conversationFull = [...respuestaPromts.data.respuesta[0].prompt, newMesgAsist];
      setChatId(id);
      setMessages(conversationFull);
    } else {
      console.error('La respuesta no contiene los datos esperados:', respuestaPromts);
    }
    setLoadingUserChats(false);
  };

  /**
   * Manda a busar la lista de nuevos chats
   * @param {*} isMounted - booleano
   */
  async function handleLoadUsersChats(isMounted) {
    if (!respuestaUserChats) {
      setLoadingUserChats(true);
    }

    try {
      const respuesta = await UsersChatService(user);
      if (isMounted && respuesta && respuesta.data) {
        if (respuestaUserChats) {
          setRespuestaUserChats(respuesta.data);
          let transformArr = Object.entries(respuesta.data)[0];
          let reducer = transformArr[1];
          let reducerChatId = Object.entries(reducer)[0][1][0]?.id;
          if (reducerChatId && !selectedChatId) {
            showSkeleton(false);
            setSelectedChatId(reducerChatId);
          }
        } else {
          setRespuestaUserChats(respuesta.data);
        }
      } else {
        console.error('La respuesta no contiene los datos esperados:', respuesta);
      }
    } catch (error) {
      console.error('Error al cargar los clientes:', error);
    }

    if (isMounted) {
      setLoadingUserChats(false);
    }
  }

  useEffect(() => {
    let isMounted = true;

    handleLoadUsersChats(isMounted);

    return () => {
      isMounted = false;
    };
  }, [user]);

  /**
   * Expande el contenedor de features
   */
  const handleExpandFeatures = () => {
    setExpandFeatures(!expandFeatures);
  };

  /**
   * Expande el contenedor de bugs
   */
  const handleExpandBugs = () => {
    setExpandBugs(!expandBugs);
  };

  return (
    <MainLayout
      user={user}
      handleSidebarDimensions={handleSidebarDimensions}
      blackModeTrigger={blackModeTrigger}
      currentThemeParams={currentThemeParams}
      handleSignOut={handleSignOut}>
      <div className="main-container">
        <NewVersionInfo
          displayVersion={displayVersion}
          currentThemeParams={currentThemeParams}
          currentVersion={currentVersion}
          data={NEW_VERSION_DATA}
          saveCurrentVersion={saveCurrentVersion}
          expandFeatures={expandFeatures}
          expandBugs={expandBugs}
          themeIconFeaturesColor={themeIconFeaturesColor}
          handleExpandFeatures={handleExpandFeatures}
          handleExpandBugs={handleExpandBugs}
        />
        {/* Sidebar */}
        <SidebarLayout
          user={user}
          currentThemeParams={currentThemeParams}
          reduceSidebar={reduceSidebar}
          onChatClick={resetStates}
          handleSidebarDimensions={handleSidebarDimensions}>
          {loadingUserChats ? (
            <div className="chat-list-load">
              <Orbit size={25} speed={1.5} color={themeOrbitColor} />
            </div>
          ) : (
            <ChatList
              currentThemeParams={currentThemeParams}
              handleSidebarDimensions={handleSidebarDimensions}
              reduceSidebar={reduceSidebar}
              chats={respuestaUserChats?.respuesta}
              handleChatId={handleChatId}
              handleOptionsClick={handleOptionsClick}
              selectedChatId={selectedChatId}
              selectedOptions={selectedOptions}
              resetStates={resetStates}
              skeletonNewChat={skeletonNewChat}
              showScroll={showScroll}
            />
          )}
        </SidebarLayout>
        {/* Container */}
        <div className="main-info-wrapper themeMain">
          {/* Main */}
          <ChatComponent
            messages={messages}
            setMessages={setMessages}
            user={user}
            chatId={chatId}
            setChatId={setChatId}
            currentThemeParams={currentThemeParams}
            themeOrbitColor={themeOrbitColor}
            showWelcome={showWelcome}
            showWelcomeTrigger={showWelcomeTrigger}
            handleCopyClick={handleCopyClick}
            handleLoadUsersChats={handleLoadUsersChats}
            showSkeleton={showSkeleton}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Main;
