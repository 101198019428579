import axios from 'axios';

const GetAppVersion = async () => {
  let app_version;
  try {
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    await axios
      .get('/site-version.json', config)
      .then((response) => {
        app_version = response.data.version;
      })
      .catch((error) => {
        console.error('Error al hacer la solicitud:', error);
      });
    return app_version;
  } catch (error) {
    console.error('Error fetching access token', error);
  }
};

export default GetAppVersion;
