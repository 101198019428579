import React, { useState, useRef, useEffect } from 'react';
import { Orbit } from '@uiball/loaders';
import MessageComponent from './MessageComponent';
import GetAppVersion from '../services/VersionService';
import { ChatService, CancelRequest } from '../services/ChatService';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { handleDownloadMessages } from '../utils/exportData';
import './ChatComponent.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { Send } from '../assets/icons/Send';
import { AthenaIsotipo } from '../assets/icons/AthenaIsotipo';
import ChatColor from '../assets/images/Chat.svg';
import BalanceColor from '../assets/images/Financial-Balance.svg';
import CodingColor from '../assets/images/Coding-Folder.svg';
import PencilColor from '../assets/images/Pencil-color.svg';
import { WelcomeComponent } from '../components/WelcomeComponent';

const ChatComponent = ({
  messages,
  setMessages,
  user,
  chatId,
  setChatId,
  currentThemeParams,
  themeOrbitColor,
  showWelcome,
  showWelcomeTrigger,
  handleCopyClick,
  handleLoadUsersChats,
  showSkeleton
}) => {
  const messagesContainerRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [sending, setSending] = useState(false);
  const textareaRef = useRef(null);

  /**
   * Invoca los elementos de WelcomeComponent para ser utilizados en la construcción del componente
   */
  const { IconImg, WelcomeBox, WelcomeContainer } = WelcomeComponent();

  /**
   * Variable para almacenar dimension de caja de texto
   */
  const heightEdit = textareaRef?.current?.style?.height ? textareaRef?.current?.style?.height : 64;

  /**
   * Función para descargar chat
   */
  const downloadMessages = () => {
    confirmAlert({
      title: 'Confirmar',
      message: '¿Descargar historial de mensajes?',
      buttons: [
        {
          label: 'Sí',
          onClick: () => handleDownloadMessages(messages, toast)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSendMessage = async () => {
    setPrompt('');
    showSkeleton(true);
    const sendMsgTrigger = async () => {
      showWelcomeTrigger(!showWelcome);
      let app_version_s3 = await GetAppVersion();
      let app_version = process.env.REACT_APP_VERSION;
      messagesContainerRef.current.style.overflowY = 'hidden';
      if (app_version_s3 !== app_version) {
        // Borrar caches si es posible
        if ('caches' in window) {
          caches.keys().then((names) => {
            for (let name of names) caches.delete(name);
          });
        }
        // Recargar página
        window.location.reload(true);
      }

      // Disable the send button and show the spinner
      setSending(true);
      try {
        let respChatID = await ChatService(messages, prompt, setMessages, setSending, user, chatId);
        setChatId(respChatID);
        handleLoadUsersChats(true);
        setPrompt('');
        textareaRef.current.style.height = 'auto';

        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
        messagesContainerRef.current.style.overflowY = 'auto';
      } catch (errorService) {
        console.error(errorService);
      }
    };
    setTimeout(() => {
      sendMsgTrigger();
    }, 300);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (prompt) {
        handleSendMessage();
      }
    }
  };

  /**
   * Función que maneja el evento de cancelar una solicitud en curso a la API.
   * @returns {void}
   */
  const handleCancel = () => {
    CancelRequest();
    setSending(false);
  };

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Resetea la altura
      textarea.style.height = textarea.scrollHeight + 'px'; // Ajusta la altura
      let altura = textarea.style.height.replace('px', '');
      if (altura >= 200) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  };
  useEffect(() => {
    // Scroll al final del contenedor de mensajes cuando se agregan nuevos mensajes
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="main-info-wrapper-content themeMain-content">
      {/** Componente de despliegue de información - Main */}
      <div className="main-info-wrapper-content-top" ref={messagesContainerRef}>
        {!showWelcome || messages.length > 0 ? (
          <>
            {messages.map((message, index) => {
              // Exclude messages with 'system' tag
              if (message.role === 'system') {
                return null;
              }
              return (
                <MessageComponent
                  key={index}
                  role={message.role}
                  content={message.content}
                  currentThemeParams={currentThemeParams}
                  handleCopyClick={handleCopyClick}
                  downloadMessages={downloadMessages}
                  sending={sending}
                />
              );
            })}
          </>
        ) : (
          <WelcomeContainer
            styles={{
              styles_main_info: 'main-info-component',
              styles_main_info_top: 'main-info-component-top',
              styles_main_info_top_icon: 'main-info-component-top-icon',
              styles_main_info_top_texts: 'main-info-component-top-texts',
              styles_main_info_top_texts_title: 'main-info-component-top-texts-title poppins-bold',
              styles_main_info_top_texts_p: 'main-info-component-top-texts-p',
              styles_main_info_box_content: 'main-info-component-box-content'
            }}
            icon={<AthenaIsotipo theme={currentThemeParams} height={100} width={44} />}
            title={'¡Hola, soy Athena!'}
            text={'Dime cómo puedo colaborarte o inspírate con estas sugerencias.'}
            box={[
              <WelcomeBox
                key={'Conversación'}
                styles={{
                  styles_main_info_component_box_item:
                    'main-info-component-box-item themeMain-content-welcome-component-box-items',
                  styles_main_info_component_box_item_top: 'main-info-component-box-item-top',
                  styles_main_info_component_box_item_bottom: 'main-info-component-box-item-bottom'
                }}
                boxIcon={<IconImg img={ChatColor} styles="main-info-component-box-item-top-icon" />}
                boxTitle={'Conversación'}
                boxContent={'¿Necesito un consejo, puedes ayudar?'}
              />,
              <WelcomeBox
                key={'Comparar'}
                styles={{
                  styles_main_info_component_box_item:
                    'main-info-component-box-item themeMain-content-welcome-component-box-items',
                  styles_main_info_component_box_item_top: 'main-info-component-box-item-top',
                  styles_main_info_component_box_item_bottom: 'main-info-component-box-item-bottom'
                }}
                boxIcon={<IconImg img={BalanceColor} styles="main-info-component-box-item-top-icon" />}
                boxTitle={'Comparar'}
                boxContent={
                  'Estoy comparando los framework react y angular. ¿Puedes ayudarme a analizar los pros y los contras?'
                }
              />,
              <WelcomeBox
                key={'Código'}
                styles={{
                  styles_main_info_component_box_item:
                    'main-info-component-box-item themeMain-content-welcome-component-box-items',
                  styles_main_info_component_box_item_top: 'main-info-component-box-item-top',
                  styles_main_info_component_box_item_bottom: 'main-info-component-box-item-bottom'
                }}
                boxIcon={<IconImg img={CodingColor} styles="main-info-component-box-item-top-icon" />}
                boxTitle={'Código'}
                boxContent={'Escribe un script de Java para realizar una búsqueda binaria'}
              />,
              <WelcomeBox
                key={'Escribir'}
                styles={{
                  styles_main_info_component_box_item:
                    'main-info-component-box-item themeMain-content-welcome-component-box-items',
                  styles_main_info_component_box_item_top: 'main-info-component-box-item-top',
                  styles_main_info_component_box_item_bottom: 'main-info-component-box-item-bottom'
                }}
                boxIcon={<IconImg img={PencilColor} styles="main-info-component-box-item-top-icon" />}
                boxTitle={'Escribir'}
                boxContent={'Escribe el esquema de un reporte de libro para ayudarme a comenzar'}
              />
            ]}
          />
        )}
      </div>

      {sending ? (
        <>
          <div className="main-info-wrapper-content-bot-stop-options-box">
            <div className="flex items-center justify-center">
              <Orbit size={25} speed={1.5} color={themeOrbitColor} />
            </div>
          </div>
          <div className="main-info-wrapper-content-bot-stop-options-box">
            <button onClick={handleCancel}>
              <div className="stop-icon themeMain-icon-stop-chat" />
              <p>Deja de responder</p>
            </button>
          </div>
        </>
      ) : null}
      <div
        className="main-info-wrapper-content-bot-question-box themeMain-content-box-input"
        style={{ height: prompt ? heightEdit : 0, overflowY: 'hidden' }}>
        <div className="main-info-wrapper-content-bot-question-box-input ">
          <textarea
            ref={textareaRef}
            placeholder="Ingresa tu instrucción aquí"
            className="textarea-promt main-info-wrapper-content-bot-question-box-input-textarea"
            value={prompt}
            onKeyDown={handleKeyDown}
            onInput={resizeTextarea}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="main-info-wrapper-content-bot-question-box-send">
          <button className="send-btn" onClick={handleSendMessage}>
            <Send theme={currentThemeParams} />
          </button>
        </div>
      </div>
      <p className="disclaimer-text">
        Athena puede mostrar información imprecisa, así que comprueba siempre sus respuestas.
      </p>
    </div>
  );
};

export default ChatComponent;
