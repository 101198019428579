import React from 'react';
export const WelcomeComponent = () => {
  /**
   * Recibe imagen desde el componente
   *@param {*} img - imagen para icono a color de la caja del componente
   *@returns
   */
  const IconImg = ({ img, styles }) => {
    return <img src={img} alt="icon-color" className={styles} />;
  };
  /**
   * Recibe parametros para controlar la caja de información central del componente padre
   *@param {*} boxIcon - icono de la caja
   *@param {*} boxTitle - titulo de la caja
   *@param {*} boxContent - contenido o bajada de la caja
   *@param {*} styles - objeto con estilos editables para las secciones de la caja
   *@returns
   */
  const WelcomeBox = ({ boxIcon, boxTitle, boxContent, styles }) => {
    return (
      <div key={boxTitle} className={styles.styles_main_info_component_box_item}>
        <div className={styles.styles_main_info_component_box_item_top}>
          {boxIcon}
          <h4>{boxTitle}</h4>
        </div>
        <div className={styles.styles_main_info_component_box_item_bottom}>
          <p>{boxContent}</p>
        </div>
      </div>
    );
  };

  /**
   * Contenedor general de la información
   *@param {*} icon - icono Athena que acompaña al titulo general
   *@param {*} title - titulo general del componente
   *@param {*} text - contenido o bajada general del componente
   *@param {*} styles - objeto con estilos editables para las secciones del container
   *@returns
   */
  const WelcomeContainer = ({ icon, title, text, box, styles }) => {
    return (
      <div className={styles.styles_main_info}>
        <div className={styles.styles_main_info_top}>
          <div className={styles.styles_main_info_top_icon}>{icon}</div>
          <div className={styles.styles_main_info_top_texts}>
            <h1 className={styles.styles_main_info_top_texts_title}>{title}</h1>
            <p className={styles.styles_main_info_top_texts_p}>{text}</p>
          </div>
        </div>
        <div className={styles.styles_main_info_box_content}>{box}</div>
      </div>
    );
  };

  return { IconImg, WelcomeBox, WelcomeContainer };
};
