import React from 'react';

const Modal = ({ isOpen, closeModal, title, message }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={closeModal}></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-md w-full">
          {/* Modal Header */}
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">{title || 'Alert'}</h3>
          </div>
          {/* Modal Body */}
          <div className="p-6">
            <p className="text-sm text-gray-500">{message}</p>
          </div>
          {/* Modal Footer */}
          <div className="px-6 py-4 bg-gray-50 flex justify-end items-center">
            <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
