import React from 'react';
import './floatOptionsBox.css';

export default function FloatOptionsBox() {
  /**
   * Opcion para clickear
   * @param {*} icon - Icono de la opcion
   * @param {*} optionName - Descripcion de la opcion
   * @param {*} action - Activador de la opcion
   * @returns
   */
  const OptionsItems = ({ icon, optionName, action }) => {
    return (
      <button className="optionsItems" onClick={action}>
        {icon} <p>{optionName}</p>
      </button>
    );
  };
  /**
   * Caja que despliega las opciones ejecutables
   * @param {*} optionItem - Opcion a incluir en la caja
   * @param {*} styles - Estilos editables de la caja
   * @param {*} compareState - Estado para enviarle como parametro al componente y validar cual objeto acompañará
   * @param {*} validator - Parametro contra el que validara "compareState" y definir si el componente queda oculto o visible
   * @returns
   */
  const OptionsBoxContainer = ({ optionItem, styles, compareState, validator, X, Y, load }) => {
    // valida si ya tienen valor los parametros de posiciones y luego si el id coincide con la posicion de la lista para pintarse
    const defaultValues = {
      top: Y ? Y : 0,
      left: X ? X : 0
    };

    let validate = !load && X && Y && compareState === validator ? 'flex' : 'none';
    return (
      <div
        className={`${styles} themeSidebar-three-options-selectors`}
        style={{
          display: validate,
          position: 'absolute',
          top: defaultValues.top,
          left: defaultValues.left
        }}>
        {optionItem}
      </div>
    );
  };

  return { OptionsItems, OptionsBoxContainer };
}
