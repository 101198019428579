import axios from 'axios';
import GetAccessToken from '../services/AuthService';

const ReportChatService = async (url_log, fecha_desde, fecha_hasta, app_business, app_origin) => {
  console.debug('[ReportChatService] Inicio fecha_desde:' + fecha_desde + ', fecha_hasta: ' + fecha_hasta);
  if (app_business === '') {
    app_business = 'TInet';
  }
  let access_token = await GetAccessToken();
  let config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    }
  };
  let request_reporte = {
    date_from: fecha_desde,
    date_to: fecha_hasta,
    app_environment: process.env.REACT_APP_ENV,
    app_business: app_business,
    app_origin: app_origin
  };
  try {
    let response = await axios.post(url_log, request_reporte, config);
    console.debug('[ReportChatService] Fin.');
    return response;
  } catch (error) {
    console.debug('[ReportChatService] Error guardando logs:', error);
    return null;
  }
};
export default ReportChatService;
