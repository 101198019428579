import React from 'react';

/**
 * ChatItem se reubica para recibir otros parametros y desplegar distintos botones de forma independiente
 * @param {*} param0
 * @returns
 */
const ChatItem = ({ chatName, chatId, onChatClick, icon, type, reduceSidebar, handleSidebarDimensions }) => {
  // valida si pintar o no el icono cuando ingresa a reduccion de tamaño el sidebar
  const display = reduceSidebar ? 'none' : 'flex';

  return (
    <>
      {type === 'new-chat' && (
        <div className="main-chat-item-btn-responsive">
          <div className="main-chat-item-btn-tag" style={{ display: display }}>
            {icon}
          </div>
          <div className={!reduceSidebar ? 'main-chat-item-btn-container' : 'main-chat-item-btn-container.reduce'}>
            {!reduceSidebar ? (
              <button
                onClick={() => onChatClick(chatId, chatName)}
                className="text-start w-full text-sm font-medium focus:outline-none main-chat-item-btn-tag">
                <p>{chatName}</p>
              </button>
            ) : (
              <button
                onClick={() => onChatClick(chatId, chatName)}
                className="focus:outline-none main-chat-item-btn-tag-responsive">
                {icon}
              </button>
            )}
          </div>
        </div>
      )}

      {type === 'new-chat-mobile' && (
        <div className="main-chat-item-btn">
          <div className="min-chat-icon">{icon}</div>
          <div className="ml-3 w-full">
            <button
              onClick={() => [handleSidebarDimensions(), onChatClick(chatId, chatName)]}
              className="text-start w-full text-sm font-medium focus:outline-none">
              {chatName}
            </button>
          </div>
        </div>
      )}
      {!type && (
        <div className="main-chat-item-btn">
          <div className="min-chat-icon">{icon}</div>
          <div className="ml-3 w-full">
            <button
              onClick={() => onChatClick(chatId, chatName)}
              className="text-start w-full text-sm font-medium focus:outline-none">
              {chatName}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default ChatItem;
