import React from 'react';

/**
 * Boton estandar para recibir iconos planos y pintarlos
 * @param {*} buttomIcon - recibe icono
 * @param {*} action - recibe trigger
 * @param {*} estyles - recibe clase para estilos del boton
 * @returns
 */
export default function FlatButtom({ buttomIcon, action, estyles_button }) {
  return (
    <button className={estyles_button} onClick={action}>
      {buttomIcon}
    </button>
  );
}
