import React from 'react';
import { ThemeIconColor_enum } from '../../utils/constants';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 20,
  height: 20,
  themeIcon: ThemeIconColor_enum.LIGHT
};
export const Sun = ({ width, height, theme }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 24 24"
    fill={changeIconTheme(theme)}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" />
    <path d="M12 0C12.4142 0 12.75 0.335786 12.75 0.75V3.75C12.75 4.16421 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.16421 11.25 3.75V0.75C11.25 0.335786 11.5858 0 12 0Z" />
    <path d="M12 19.5C12.4142 19.5 12.75 19.8358 12.75 20.25V23.25C12.75 23.6642 12.4142 24 12 24C11.5858 24 11.25 23.6642 11.25 23.25V20.25C11.25 19.8358 11.5858 19.5 12 19.5Z" />
    <path d="M24 12C24 12.4142 23.6642 12.75 23.25 12.75H20.25C19.8358 12.75 19.5 12.4142 19.5 12C19.5 11.5858 19.8358 11.25 20.25 11.25H23.25C23.6642 11.25 24 11.5858 24 12Z" />
    <path d="M4.5 12C4.5 12.4142 4.16421 12.75 3.75 12.75H0.75C0.335786 12.75 -1.81059e-08 12.4142 0 12C1.81059e-08 11.5858 0.335786 11.25 0.75 11.25H3.75C4.16421 11.25 4.5 11.5858 4.5 12Z" />
    <path d="M20.4853 3.51477C20.7782 3.80766 20.7782 4.28253 20.4853 4.57543L18.364 6.69675C18.0711 6.98964 17.5962 6.98964 17.3033 6.69675C17.0104 6.40385 17.0104 5.92898 17.3033 5.63609L19.4246 3.51477C19.7175 3.22187 20.1924 3.22187 20.4853 3.51477Z" />
    <path d="M6.6967 17.3033C6.98959 17.5962 6.98959 18.0711 6.6967 18.364L4.57538 20.4853C4.28248 20.7782 3.80761 20.7782 3.51472 20.4853C3.22182 20.1924 3.22182 19.7176 3.51472 19.4247L5.63604 17.3033C5.92893 17.0105 6.4038 17.0105 6.6967 17.3033Z" />
    <path d="M20.4853 20.4853C20.1924 20.7782 19.7175 20.7782 19.4246 20.4853L17.3033 18.364C17.0104 18.0711 17.0104 17.5963 17.3033 17.3034C17.5962 17.0105 18.0711 17.0105 18.364 17.3034L20.4853 19.4247C20.7782 19.7176 20.7782 20.1925 20.4853 20.4853Z" />
    <path d="M6.6967 6.69676C6.40381 6.98966 5.92893 6.98966 5.63604 6.69676L3.51472 4.57544C3.22182 4.28255 3.22183 3.80768 3.51472 3.51478C3.80761 3.22189 4.28249 3.22189 4.57538 3.51478L6.6967 5.6361C6.98959 5.929 6.98959 6.40387 6.6967 6.69676Z" />
  </svg>
);
