import React from 'react';
import changeIconTheme from '../../../src/helpers/changeIconTheme';
/**
 * Propiedades por defecto para el componente
 */
const defaultProps = {
  width: 221,
  height: 51
};
export const AthenaText = ({ width, height, theme, styles }) => (
  <svg
    width={width ? width : defaultProps.width}
    height={height ? height : defaultProps.height}
    viewBox="0 0 293 51"
    fill={changeIconTheme(theme)}
    className={styles}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M38.2176 51L35.1936 42.072H16.3296L13.3056 51H0.417625L18.7056 0.455998H32.9616L51.2496 51H38.2176ZM32.0256 32.568L25.7616 14.064L19.5696 32.568H32.0256ZM54.0796 10.32V0.455998H93.1756V10.32H79.7836V51H67.4716V10.32H54.0796ZM130.819 51V30.192H111.667V51H99.3546V0.455998H111.667V20.256H130.819V0.455998H143.131V51H130.819ZM182.979 0.455998V10.32H164.331V20.544H180.819V30.048H164.331V41.136H182.979V51H152.019V0.455998H182.979ZM223.876 0.455998H236.188V51H223.876L203.284 19.824V51H190.972V0.455998H203.284L223.876 31.776V0.455998ZM279.6 51L276.576 42.072H257.712L254.688 51H241.8L260.088 0.455998H274.344L292.632 51H279.6ZM273.408 32.568L267.144 14.064L260.952 32.568H273.408Z" />
  </svg>
);
