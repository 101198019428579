import React, { useState, useEffect } from 'react';
import SecurityTinetReportsComponent from '../components/security/SecurityTinetReportsComponent';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import HorizontalBarChart from '../components/charts/HorizontalBarChart';
import BarChart from '../components/charts/BarChart';
import ReportChatService from '../services/ReportChatService';
import StatisticsBlock from '../components/cards/StatisticsBlockComponent';
import MainLayout from './layouts/MainLayout';
import ReportFilter from '../components/commons/ReportFilterComponent';
import { ORIGIN } from '../utils/constants';
const TinetReportesPage = ({ user, handleSidebarDimensions, blackModeTrigger, currentThemeParams, handleSignOut }) => {
  const filtroTO = {
    startDate: new Date(),
    endDate: new Date(),
    clientValue: 'Todos',
    originValue: ORIGIN.ALL
  };
  const allowedGroups = ['tinet_reports_access'];
  const [permiso, setPermiso] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState(filtroTO);
  const [responseTimeData, setResponseTimeData] = useState(null);
  const [concurrencyTimeData, setConcurrencyTimeData] = useState(null);
  const [respuestaReporte, setRespuestaReporte] = useState(null);

  const [isExportable, setIsExportable] = useState(false);

  const handleUpdateFiltro = (nuevoFiltro) => {
    setFiltro(nuevoFiltro);
  };

  useEffect(() => {
    const checkIfExportable = () => {
      return !(
        !loading &&
        (!respuestaReporte ||
          (filtro.clientValue === ORIGIN.ALL &&
            (!respuestaReporte?.business_reports || respuestaReporte?.business_reports.length === 0)) ||
          (filtro.clientValue !== ORIGIN.ALL &&
            (!respuestaReporte?.users_reports || respuestaReporte?.users_reports.length === 0)) ||
          !respuestaReporte?.daily_reports ||
          respuestaReporte?.daily_reports.length === 0)
      );
    };
    setIsExportable(checkIfExportable());
  }, [loading, respuestaReporte, filtro.clientValue]);

  useEffect(() => {
    let isMounted = true;

    async function fetchPermiso() {
      try {
        const result = await SecurityTinetReportsComponent(allowedGroups, user);
        if (isMounted) {
          setPermiso(result);
        }
      } catch (error) {
        console.error('Error al obtener permisos:', error);
      }
    }

    fetchPermiso();
    return () => {
      isMounted = false;
    };
  }, [user]);
  useEffect(() => {
    let isMounted = true;

    async function handleLoadReport() {
      setLoading(true);

      try {
        const respuesta = await ReportChatService(
          process.env.REACT_APP_API_REPORT_LOG,
          filtro.startDate.getTime(),
          filtro.endDate.getTime(),
          filtro.clientValue,
          filtro.originValue
        );

        if (isMounted && respuesta && respuesta.data.respuesta) {
          let timeData = [
            {
              name: 'Promedio Tiempo Respuesta',
              value: respuesta.data.respuesta.totals_date_range.total_prom_tiempo_resp_prom
            },
            {
              name: 'Tiempo Respuesta Minimo',
              value: respuesta.data.respuesta.totals_date_range.total_prom_tiempo_resp_min
            },
            {
              name: 'Tiempo Respuesta Maximo',
              value: respuesta.data.respuesta.totals_date_range.total_prom_tiempo_resp_max
            }
          ];
          let concurrencyData = [
            {
              name: 'Promedio Concurrencia',
              value: respuesta.data.respuesta.totals_date_range.total_prom_concurrencia_prom
            },
            {
              name: 'Mínima Concurrencia',
              value: respuesta.data.respuesta.totals_date_range.total_prom_concurrencia_min
            },
            {
              name: 'Máxima Concurrencia',
              value: respuesta.data.respuesta.totals_date_range.total_prom_concurrencia_max
            }
          ];
          setRespuestaReporte(respuesta.data.respuesta);
          setResponseTimeData(timeData);
          setConcurrencyTimeData(concurrencyData);
        } else {
          console.info('La respuesta incorrecta o servicio no encontrado.');
        }
      } catch (error) {
        console.error('Error al cargar el reporte:', error);
      }

      if (isMounted) {
        setLoading(false);
      }
    }

    handleLoadReport();
    return () => {
      isMounted = false;
    };
  }, [filtro]);
  return (
    <MainLayout
      user={user}
      handleSidebarDimensions={handleSidebarDimensions}
      blackModeTrigger={blackModeTrigger}
      currentThemeParams={currentThemeParams}
      handleSignOut={handleSignOut}>
      {/* Container */}
      <div className="flex-1 overflow-y-auto mt-2">
        <div className="flex flex-col h-screen">
          {permiso.get('tinet_reports_access') ? (
            <div className="flex-1 overflow-y-auto z-0 " style={{ maxWidth: '90%', alignSelf: 'center' }}>
              <div className="w-full">
                <ReportFilter onFilterChange={handleUpdateFiltro} isExportable={isExportable} />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-2 export-pdf">
                {!loading &&
                  (!respuestaReporte ||
                    (filtro.clientValue === ORIGIN.ALL &&
                      (!respuestaReporte?.business_reports || respuestaReporte?.business_reports.length === 0)) ||
                    (filtro.clientValue !== ORIGIN.ALL &&
                      (!respuestaReporte?.users_reports || respuestaReporte?.users_reports.length === 0)) ||
                    !respuestaReporte?.daily_reports ||
                    respuestaReporte?.daily_reports.length === 0) && (
                    <div className="col-span-full bg-red-100 text-red-800 text-center p-4 rounded-lg shadow">
                      Sin información para los filtros seleccionados
                    </div>
                  )}
                {responseTimeData && (
                  <StatisticsBlock data={responseTimeData} title="Tiempos de respuesta" units={'(s)'} />
                )}
                {concurrencyTimeData && (
                  <StatisticsBlock data={concurrencyTimeData} title="Concurrencias (x min)" units={'(trx)'} />
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 pt-2 export-pdf">
                {respuestaReporte && filtro.clientValue === 'Todos' && (
                  <HorizontalBarChart data={respuestaReporte?.business_reports} title="Transacciones por Clientes" />
                )}
                {respuestaReporte && filtro.clientValue !== 'Todos' && (
                  <HorizontalBarChart data={respuestaReporte?.users_reports} title="Transacciones por Usuarios" />
                )}
                {respuestaReporte && (
                  <BarChart
                    data={respuestaReporte?.daily_reports}
                    title={'Transacciones por Dia'}
                    labelInput={'Metricas'}
                  />
                )}
              </div>
            </div>
          ) : (
            <div>No tienes permisos!!!!</div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default TinetReportesPage;
